import React, {useState, useEffect} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link,useParams} from "react-router-dom";
import {getcard, updatecard} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';import { toast } from 'react-toastify';

export default function UpdateCard() {

    const {cardId} = useParams()
    const {admin, token} = isAuthenticated()
    
    const [values, setValues] = useState({
        cardNo: "",
        fuelSupplier: "",
        error: false,
        updatedCard: "",
        loading: false,
        isLoaded: "",
        didRedirect: false
    });
    
    const { cardNo, fuelSupplier, error, updatedCard, loading, isLoaded, didRedirect } = values

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getcard(cardId, admin._id, token).then(data => {
          if(data.error){
            setValues({ ...values, loading: "", isLoaded: "", error: data.error })
          } else{
            setValues({
                ...values,
                ...data,
                error: "",
                loading: "",
                isLoaded: true
            });
            }
        })
        .catch(err => {
            console.log('Error preloading the card:', err);
            setValues({ ...values, error: "Error preloading the card", loading: "" });
            toastifyMessage("error", 'Error preloading the card');
        });  
      }
  
    useEffect(() => {
        preload()
      },[])


    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, updatedCard: "", loading: true})

        // Field validations
        const requiredFields = [cardNo];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedCard: ""});
            return;
        }

        updatecard(cardId, token, admin._id, {cardNo, fuelSupplier})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", updatedCard: ""})
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        ...data,                       
                        loading: "",
                        error: "",
                        updatedCard: "Fuel card updated succesfully"
                    });
                    toastifyMessage("success", 'Card updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the card:', err);
                setValues({ ...values, error: "Error updating the card", loading: "" });
                toastifyMessage("error", 'Error updating the card');
            });  

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedCard ? "" : "none" }}
        >
            <h4>{updatedCard}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    const updateCardForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-dark"> Fuel Card Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("cardNo")}
                    type="text"
                    name="cardNo"
                    value={cardNo}
                    placeholder={cardNo}
                    autoFocus
                    required
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Fuel Supplier </label>
                <input
                    className="form-control"
                    onChange={handleChange("fuelSupplier")}
                    type="text"
                    name="fuelSupplier"
                    value={fuelSupplier}
                    placeholder={fuelSupplier}
                />
            </div>
            <button 
                onClick ={onSubmit}
                className="btn btn-outline-info"> Update Fuel Card 
            </button>
        </form>
    )

    return (
        <Base
        title="Card Dashboard"
        description="Edit the Card Number"
        className="container bg-info p-4"
        >

            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                {isLoaded && updateCardForm()}
                {successMessage()}
                {warningMessage()}
                
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage/>
        </Base>
    )
}
