import React, {useState} from 'react'
import Base from "../../core/Base"
import { isAuthenticated } from "../../auth/helper/adminIndex"
import {Link} from "react-router-dom"
import {createcard} from "../helper/adminapicall"
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toast } from 'react-toastify';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddCard() {

    const {admin, token} = isAuthenticated()
    const [values, setValues] = useState({
        cardNo: "",
        fuelSupplier: "",
        error: false,
        createdCard: "",
        loading: false,
        didRedirect: false
    });

    const { cardNo, fuelSupplier, error, createdCard, loading, didRedirect } = values

    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, createdCard: "", loading: true})

         // Field validations
         const requiredFields = [cardNo];
         if (requiredFields.some(field => !field)) {
             setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", createdCard: ""});
             toastifyMessage("warning", 'All the fields marked with * are mandatory');
             return;
         }

        createcard(admin._id, token, {cardNo, fuelSupplier})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", createdCard: "" });
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        cardNo: "",
                        fuelSupplier: "",                       
                        loading: "",
                        error: "",
                        createdCard: "Fuel Card added succesfully"
                    });
                    toastifyMessage("success", 'Fuel card added successfully');
                }
            })
            .catch(err => {
                console.log('Error onboarding the card:', err);
                setValues({ ...values, error: "Error onboarding the card", loading: "" });
                toastifyMessage("error", 'Error onboarding the card');
              });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: createdCard ? "" : "none" }}
        >
            <h4>{createdCard}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const createCardForm = () => (
        <>
            <form className="mt-4">
                <div className="form-group">
                    <label className="text-light"> Fuel Card Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("cardNo")}
                        type="text"
                        value={cardNo}
                        name="cardNo"
                        placeholder="Fuel card"
                        autoFocus
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Fuel Supplier </label>
                    <input
                        className="form-control"
                        onChange={handleChange("fuelSupplier")}
                        type="text"
                        value={fuelSupplier}
                        name="fuelSupplier"
                        placeholder="Fuel Card Supplier"
                    />
                </div>
                <button 
                    onClick ={onSubmit}
                    className="btn btn-outline-info"> Add Fuel Card 
                </button>
            </form>
            {LoadingScreen(loading)}
        </>
    )

    return (
        <Base
            title="Fuel Card Dashboard"
            description="Add a new Card"
            className="container bg-info p-4"
        >
            {returnToAdminDashboard()}
            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                    {createCardForm()}
                    {successMessage()}
                    {warningMessage()}
                
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    )
}
