import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { disAuthenticated } from "../../auth/helper/driverIndex";
import { getlogsheetsfordriver } from '../helper/driverapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import LogSheetTableView from '../../core/helper/LogSheetTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../DriverSideSheet.css';
import { getTruckNo, getTrailerNo, getDate, getDayOfTheWeek } from '../../core/helper/helperFunctions';


export default function ManageLogSheet() {
    const { driver, token } = disAuthenticated();
    const [values, setValues] = useState({
        startDate: moment().subtract(13, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        error: "",
        isLoaded: "",
        logSheets: [],
        firstName: driver && driver.firstName ? driver.firstName : "",
        lastName: driver && driver.lastName ? driver.lastName : "",
        isReimbursementRequired: false
    });
    const [sheetName, setSheetName] = useState("Time Cards");
    const [startDateText, setStartDateText] = useState(moment(values.startDate).format('MMMM D, YYYY'));
    const [endDateText, setEndDateText] = useState(moment(values.endDate).format('MMMM D, YYYY'));

    const { startDate, endDate, loading, error, isLoaded, logSheets, firstName, lastName, isReimbursementRequired } = values;
    let reimbursementCounter = false;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getlogsheetsfordriver(driver._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
            } else {
                const startDateText = moment(startDate).format('MMMM D, YYYY');
                const endDateText = moment(endDate).format('MMMM D, YYYY');
                setSheetName(`Time Cards for ${startDateText} - ${endDateText}`);
                setStartDateText(startDateText);
                setEndDateText(endDateText);
                for (let i = 0; i < data.length; i++) {
                    if (data[i].reimbursementLocation && data[i].reimbursementDate) {
                        reimbursementCounter = true;
                        break;
                    }
                }
                setValues({ ...values,  logSheets: data, loading: "", isLoaded: true, isReimbursementRequired: reimbursementCounter})
                if(data.length===0){
                    toastifyMessage("warning", 'No time card found');
              }
            }
        })
        .catch(err => {
            console.log('Error loading the time cards', err);
            setValues({ ...values, error: "Error loading the time cards", loading: "" });
            toastifyMessage("error", 'Error loading the time cards');
          });
    };

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the table
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Time Card`, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`Driver: ${firstName} ${lastName}`, 10, 30);
        doc.text(`Report Duration: ${startDateText} to ${endDateText}`, 10, 35);

        // Define table columns based on LogSheetTableView component
        const tableColumns = [
            'Sr. No.', 'Date', 'Day', 'Truck', 'Trailer', 'Start City', 'Finish City', 'Start Odometer', 'End Odometer', 'Down time', 'Billable Start Time', 'Billable End Time', 'Deduction (in hour)', 'Notes', 'Number of Loads Delivered', 'Night out', 'Reimbursement'
        ];
        if (isReimbursementRequired) {
            tableColumns.push('Reimbursement Date', 'Reimbursement Purpose', 'Reimbursement Location', 'Reimbursement Amount');
        }

        // Define table rows based on logSheets data
        const tableRows = logSheets.map((logSheet, index) => {
            const row = [
                index + 1,
                logSheet.date ? getDate(logSheet.date) : "",
                getDayOfTheWeek(logSheet),
                getTruckNo(logSheet, true),
                getTrailerNo(logSheet, true),
                logSheet.pickUpCity || "",
                logSheet.deliveryCity || "",
                logSheet.startOdometer || "",
                logSheet.endOdometer || "",
                (logSheet.downTime || logSheet.downTime === 0) ? logSheet.downTime : "",
                logSheet.timeIn || "",
                logSheet.timeOut || "",
                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                (logSheet.notes? 
                        (logSheet.deductionNotes ? 
                            (logSheet.notes + "\n" + logSheet.deductionNotes):
                            logSheet.notes
                        ) : 
                        (
                            logSheet.deductionNotes?logSheet.deductionNotes:"-"
                        )
                ),
                logSheet.numberOfLoads || "-",
                logSheet.nightOut ? (logSheet.nightOutPurpose ? ('Yes\n' + logSheet.nightOutPurpose) : 'Yes'): 'No',
                logSheet.reimbursement ? 'Yes' : 'No'
            ];
            if (isReimbursementRequired) {
                row.push(
                    logSheet.reimbursement && logSheet.reimbursementDate ? getDate(logSheet.reimbursementDate) : "-",
                    logSheet.reimbursement && logSheet.reimbursementPurpose ? logSheet.reimbursementPurpose : "-",
                    logSheet.reimbursement && logSheet.reimbursementLocation ? logSheet.reimbursementLocation : "-",
                    logSheet.reimbursement && (logSheet.reimbursementAmount || logSheet.reimbursementAmount === 0) ? logSheet.reimbursementAmount : "-"
                );
            }
            return row;
        });

        doc.autoTable({
            startY: 45,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 7,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add the footer strip
        let finalY = doc.previousAutoTable.finalY + 10;

        const stripY = finalY + 50;
        const stripHeight = 5;

        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }
        
        const filename = (firstName && lastName) ? ('time_cards_' + (firstName?firstName:"") + (lastName?lastName:"") + '.pdf') : "time_cards.pdf";
        doc.save(filename);
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download
                </button>
            </div>
        </div>
    );

    return (
        <Base title="Time Card Dashboard" description="Manage/Edit the time cards">
            <div className="log-dashboard">
                <div className="log-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5">Time Cards</h2>
                </div>
                <div className="log-header">{dateFilterForm()}</div>
                <div id="log-sheet-table">
                    <LogSheetTableView logSheets={logSheets} editURL={`/driver/update/logsheet/`} isAggregated={true} isAdmin={false} isDriver={true} isBillingFeatureRequired={false} isReimbursementRequired={isReimbursementRequired} />
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
};
