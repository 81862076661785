import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getalltripcardsaggregatedbydriver } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import PreparingDownload from '../../core/helper/PreparingDownload';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import '../AdminSideSheet.css';
import { getTruckNo, getTrailerNo, getDate, getDayOfTheWeek, getBillableTotalHours, getLoadsSubTotal } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";

// Total Amounts
let globalLoadTotal = 0;
let globalNightOutTotal = 0;
let globalSubTotal = 0;
let globalHSTTotal = 0;
let globalInvoiceTotal = 0;
let globalBillableTotalTime = 0;
let globalDeductions = 0;
let globalNumberOfLoads = 0;

export default function DownloadTimeCards() {
    const { admin, token } = isAuthenticated();

    const [values, setValues] = useState({
        startDate: moment().subtract(13, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        error: ""
    });

    const { startDate, endDate, loading, error } = values;

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        downloadTripCardPDF();
    };
   
    // Download as PDF
    const downloadTripCardPDF = () => {

        // Initiate the payroll document
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Backend call to get the aggregated records
        setValues({ ...values, loading: true });
        getalltripcardsaggregatedbydriver(admin._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "" });
                toastifyMessage("error", data.error);
            } else {
                // Throw a warning if no time card exists for the dates
                if(data.length===0){
                    toastifyMessage("warning", 'No time card found');
                 }

                 else{
                    for (let i = 0; i < data.length; i++) {
                        // Add a new page for each driver
                        if(i>0)
                            doc.addPage();

                        if(data[i]._id && data[i]._id[0] && data[i]._id[0]._id){
                            // If a valid driver exists, add a page in the document for the driver
                            // LHS Content
                            doc.setFontSize(10);
                            doc.setFont('helvetica', 'bold');
                            doc.text(`Invoice #: ${
                                (data[i]._id[0].firstName).substring(0, 1) +
                                (data[i]._id[0].lastName).substring(0, 1) + 'I#' +
                                (moment(startDate).format('DDMMYYYY')) + 
                                (moment(endDate).format('DDMMYYYY'))
                            }`, 14, 5);
                            doc.text(`Invoice Date: ${moment().format('MMMM D, YYYY')}`, 14, 10);
                            doc.text(`Company Name: ${data[i]._id[0].businessName?data[i]._id[0].businessName:""} (${data[i]._id[0].firstName?data[i]._id[0].firstName:""} ${data[i]._id[0].lastName?data[i]._id[0].lastName:""})`, 14, 15);
                            doc.text(`Address: ${data[i]._id[0].businessAddress?data[i]._id[0].businessAddress:""}`, 14, 20);
                            doc.text(`Invoice Period: ${moment(startDate).format('MMMM D, YYYY')} - ${moment(endDate).format('MMMM D, YYYY')}`, 14, 25);

                            // RHS Content
                            doc.setFontSize(25);
                            doc.setFont('helvetica', 'bold');
                            doc.text(`INVOICE`, 245, 10);

                            doc.setFontSize(10);
                            doc.text(`Bill To: Triple Trans Mill Services Limited`, 180, 20);
                            doc.text(`Address: 325 Masters Dr, Woodstock, ON N4T0L2`, 180, 25);
                        }

                        // Invoice calculations for each driver
                        globalLoadTotal = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut && sheet.ratePerHour) ? getLoadsSubTotal(sheet): 0), 10), 0)).toFixed(2);
                        globalNightOutTotal = data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.nightOut && sheet.nightOutRate) ? sheet.nightOutRate.toFixed(2) : 0), 10), 0).toFixed(2);
                        globalSubTotal = (parseFloat(globalLoadTotal) + parseFloat(globalNightOutTotal)).toFixed(2);
                        globalHSTTotal = (parseFloat(globalSubTotal) * 0.13).toFixed(2);
                        globalInvoiceTotal = (parseFloat(globalSubTotal) + parseFloat(globalHSTTotal)).toFixed(2);
                        globalBillableTotalTime = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut) ? getBillableTotalHours(sheet): 0), 10), 0)).toFixed(2);
                        globalDeductions = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.deductions) ? sheet.deductions: 0), 10), 0)).toFixed(2);
                        globalNumberOfLoads = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.numberOfLoads) ? sheet.numberOfLoads: 0), 10), 0)).toFixed(2);

                        // Push time card records to the table
                        const tableColumns = [
                            'Date', 'Day', 'Truck #', 'Billable Start Time', 'Billable End Time', 'Deductions (in hour)', 'Billable Total Hours', 'Rate', '# of Loads Delivered', 'Night out Subtotal', 'Subtotal', 'Notes'
                        ];
                    
                        // Define table rows based on time card data
                        const tableRows = data[i].data.map((logSheet, index) => {
                            const row = [
                                logSheet.date ? getDate(logSheet.date) : "",
                                getDayOfTheWeek(logSheet),
                                getTruckNo(logSheet, true),
                                logSheet.timeIn || "",
                                logSheet.timeOut || "",
                                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                                getBillableTotalHours(logSheet),
                                logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-",
                                logSheet.numberOfLoads || "-",
                                (logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-",
                                getLoadsSubTotal(logSheet, true),
                                logSheet.deductionNotes ? logSheet.deductionNotes : "-"
                            ];
                            return row;
                        });
                    
                         // Add totals row at the end of the tableRows array
                        const totalsRow = [
                            "Totals",
                            "",
                            "",
                            "",
                            "",
                            globalDeductions,
                            globalBillableTotalTime,    // Billable Total Hours
                            "",
                            globalNumberOfLoads,
                            '$' + globalNightOutTotal, // Night out Subtotal
                            '$' + globalLoadTotal,     // Grand Subtotal
                            ""
                        ];
                        tableRows.push(totalsRow);

                        doc.autoTable({
                            startY: 35,
                            head: [tableColumns],
                            body: tableRows,
                            theme: 'striped',
                            styles: {
                                fontSize: (data[i].data && (data[i].data.length>10) ? 7 : 8),
                                lineColor: [0, 0, 0],  // Border color
                                lineWidth: 0.2,        // Border width
                                halign: 'center'
                            },
                           
                            tableLineColor: [0, 0, 0],  // Outer border color
                            tableLineWidth: 0.2,        // Outer border width
                            
                            // Bold the last row (totals row)
                            didParseCell: (celldata) => {
                                const rowsCount = tableRows.length;
                                if (celldata.row.index === rowsCount - 1) {
                                    celldata.cell.styles.fontStyle = 'bold';
                                    celldata.cell.styles.fillColor = [211, 211, 211];
                                    celldata.cell.styles.textColor = [0, 0, 0];                 
                                }
                
                                // Highlight unverified loads in red
                                if (celldata.row.index >= 0 && celldata.row.section==='body' && (data[i].data[celldata.row.index] && !(data[i].data[celldata.row.index].isTripCardVerified))) {
                                    celldata.cell.styles.textColor = [255, 0, 0];
                                }
                            }
                        });

                        // Add the footer strip
                        let finalY = doc.previousAutoTable.finalY + 10;
                        if (finalY + 25 > pageHeight) {
                            doc.addPage();
                            finalY = 20; // Reset finalY for new page
                        }
                    
                        doc.setFontSize(11);
                        doc.setFont('times', 'normal');
                    
                        doc.text(`Grand Subtotal: $${globalSubTotal}`, doc.internal.pageSize.getWidth()-90, finalY);
                        doc.text(`HST (13%): $${globalHSTTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 6);
                        doc.setFont('times', 'bold')
                        doc.text(`Total: $${globalInvoiceTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 12);
                    
                        const stripY = finalY + 16;
                        const stripHeight = 5;
                    
                        if (stripY + stripHeight > pageHeight) {
                            doc.addPage();
                            doc.setFontSize(9);
                            doc.setFont('helvetica', 'bold');
                            doc.text('Please make cheque payable to above mentioned company name.', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
                            doc.text(`HST #: ${data[i]._id[0].businessTaxNumber?data[i]._id[0].businessTaxNumber:""}`, doc.internal.pageSize.getWidth() / 2, stripY + 6, { align: 'center' });
                        } else {
                            doc.setFontSize(9);
                            doc.setFont('helvetica', 'bold');
                            doc.setTextColor(0, 0, 0); // Black color for text
                            doc.text('Please make cheque payable to above mentioned company name.', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
                            doc.text(`HST #: ${data[i]._id[0].businessTaxNumber?data[i]._id[0].businessTaxNumber:""}`, doc.internal.pageSize.getWidth() / 2, stripY + 6, { align: 'center' });
                        }
                    }
                    const filename = "Payroll" + "_" + (moment(startDate).format('DDMMYYYY')) + "_" + (moment(endDate).format('DDMMYYYY')) + ".pdf";
                    doc.save(filename);
                 }
                 setValues({ ...values, loading: "" });
            }
        })
        .catch(err => {
            console.log('Error loading the time cards:', err);
            setValues({ ...values, error: "Error loading the time cards", loading: "" });
            toastifyMessage("error", 'Error loading the time cards');
        });
    };

    // Download as excel
    const downloadTripCardExcel = () => {

        // Create a new excel workbook
        const wb = XLSX.utils.book_new();

        // Backend call to get the aggregated records
        setValues({ ...values, loading: true });
        getalltripcardsaggregatedbydriver(admin._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "" });
                toastifyMessage("error", data.error);
            }
            
            else {
                // Throw a warning if no time card exists for the dates
                if(data.length===0){
                    toastifyMessage("warning", 'No time card found');
                 }

                 else{
                    for (let i = 0; i < data.length; i++) {
                        const ws = XLSX.utils.aoa_to_sheet([]);

                        if(data[i]._id && data[i]._id[0] && data[i]._id[0]._id){
                            // If a valid driver exists, add a page in the document for the driver
                            let additionalRows = [
                                ["Invoice #: " + (
                                    (data[i]._id[0].firstName).substring(0, 1) +
                                    (data[i]._id[0].lastName).substring(0, 1) + 'I#' +
                                    (moment(startDate).format('DDMMYYYY')) + 
                                    (moment(endDate).format('DDMMYYYY'))
                                )],
                                ["Invoice Date: " + (moment().format('MMMM D, YYYY'))],
                                ["Company Name: " + (data[i]._id[0].businessName?data[i]._id[0].businessName:"") + " (" + (data[i]._id[0].firstName?data[i]._id[0].firstName:"") + " " + (data[i]._id[0].lastName?data[i]._id[0].lastName:"") + ")"],
                                ["Address: " + (data[i]._id[0].businessAddress?data[i]._id[0].businessAddress:"")],
                                ["Invoice Period: " + (moment(startDate).format('MMMM D, YYYY')) + " - " + (moment(endDate).format('MMMM D, YYYY'))]
                              ];
                          
                              // Convert the additional rows to sheet format and insert them at the top
                              XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `A1` });
                          
                              // Add the invoice data on RHS
                              additionalRows = [
                                  ["INVOICE"],
                                  ["Bill To: Triple Trans Mill Services Limited"],
                                  ["Address: 325 Masters Dr, Woodstock, ON N4T0L2"]
                                ];
                            
                              // Convert the invoice data to sheet format and insert them at the top right
                              XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `G1` });
                        }

                        // Invoice calculations for each driver
                        globalLoadTotal = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut && sheet.ratePerHour) ? getLoadsSubTotal(sheet): 0), 10), 0)).toFixed(2);
                        globalNightOutTotal = data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.nightOut && sheet.nightOutRate) ? sheet.nightOutRate.toFixed(2) : 0), 10), 0).toFixed(2);
                        globalSubTotal = (parseFloat(globalLoadTotal) + parseFloat(globalNightOutTotal)).toFixed(2);
                        globalHSTTotal = (parseFloat(globalSubTotal) * 0.13).toFixed(2);
                        globalInvoiceTotal = (parseFloat(globalSubTotal) + parseFloat(globalHSTTotal)).toFixed(2);
                        globalBillableTotalTime = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.timeIn && sheet.timeOut) ? getBillableTotalHours(sheet): 0), 10), 0)).toFixed(2);
                        globalDeductions = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.deductions) ? sheet.deductions: 0), 10), 0)).toFixed(2);
                        globalNumberOfLoads = (data[i].data.reduce((total, sheet) => total + parseFloat(((sheet.numberOfLoads) ? sheet.numberOfLoads: 0), 10), 0)).toFixed(2);

                        const header = [
                            'Date', 'Day', 'Truck #', 'Billable Start Time', 'Billable End Time', 'Deductions (in hour)' , 'Billable Total Hours', 'Rate', '# of Loads Delivered', 'Night out Subtotal', 'Subtotal', 'Notes'
                        ];
                                       
                        // Define table rows based on time card data
                        const body = data[i].data.map((logSheet, index) => {
                            const row = [
                                logSheet.date ? getDate(logSheet.date) : "",
                                getDayOfTheWeek(logSheet),
                                getTruckNo(logSheet, true),
                                logSheet.timeIn || "",
                                logSheet.timeOut || "",
                                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                                getBillableTotalHours(logSheet),
                                logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-",
                                logSheet.numberOfLoads || "-",
                                (logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-",
                                getLoadsSubTotal(logSheet, true),
                                logSheet.deductionNotes ? logSheet.deductionNotes : "-"
                            ];
                            return row;
                        });
                    
                        // Add totals row at the end of the tableRows array
                        const totalsRow = [
                           "Totals",
                           "",
                           "",
                           "",
                           "",
                           globalDeductions,
                           globalBillableTotalTime,    // Billable Total Hours
                           "",
                           globalNumberOfLoads,
                           '$' + globalNightOutTotal, // Night out Subtotal
                           '$' + globalLoadTotal,     // Grand Subtotal
                           ""
                        ];
                        body.push(totalsRow);
                        
                        // Insert the table data (header and body) after the additional rows
                        XLSX.utils.sheet_add_aoa(ws, [header], { origin: `A8` });
                        XLSX.utils.sheet_add_aoa(ws, body, { origin: `A9` });

                        // Calculate column widths based on content length
                        const colWidths = header.map((col, i) => {
                          // Initialize with the width of the header
                          let max = col.length;
                        
                          // Check each cell in the column (including header)
                          body.forEach(row => {
                            const cellValue = row[i] ? row[i].toString() : "";
                            if (cellValue.length > max) {
                              max = cellValue.length;
                            }
                          });
                      
                          // Add extra width to ensure the content is not cut off
                          return { wch: max + 2 };
                        });
                    
                        // Set column widths in the worksheet
                        ws['!cols'] = colWidths;
                    
                        // Add invoice totals
                        let totalRows = body.length + 1; // +1 for the header
                    
                        // Add the additional rows in columns I and J
                        let additionalRows = [
                          ["GRAND SUBTOTAL", "$" + globalSubTotal],
                          ["HST (13%)", "$" + globalHSTTotal],
                          ["TOTAL", "$" + globalInvoiceTotal]
                        ];
                    
                        XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `I${totalRows + 11}` });
                    
                        // Add the additional rows in columns I and J
                        additionalRows = [
                          ["Please make cheque payable to above mentioned company name."],
                          ["HST #: " + (data[i]._id[0].businessTaxNumber?data[i]._id[0].businessTaxNumber:"")]
                        ];
                    
                        XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `D${totalRows + 15}` });

                        // Append the complete sheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, ((data[i]._id[0].firstName?data[i]._id[0].firstName:"") + " " + (data[i]._id[0].lastName?data[i]._id[0].lastName:"")));
                      };

                    // Generate the Excel file
                    const excelFilename = "Payroll" + "_" + (moment(startDate).format('DDMMYYYY')) + "_" + (moment(endDate).format('DDMMYYYY')) + ".xlsx";
                    XLSX.writeFile(wb, excelFilename);
                 }
                 setValues({ ...values, loading: "" });
            }
        })
        .catch(err => {
            console.log('Error loading the time cards:', err);
            setValues({ ...values, error: "Error loading the time cards", loading: "" });
            toastifyMessage("error", 'Error loading the time cards');
        });
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />

                <button
                    onClick={onSubmit}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Payroll as PDF
                </button>
                <button
                    onClick={downloadTripCardExcel}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Payroll as Excel
                </button>
            </div>
        </div>
    );

    return (
        <Base title="Time Card Dashboard" description="Download">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="log-dashboard">
                <div className="log-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}> Biweekly Time Cards </h2>
                    {dateFilterForm()}
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
}