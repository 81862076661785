import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { Link, Redirect } from "react-router-dom"
import { createincidentreport, getdrivers, getmerchants, gettrucks, gettrailers, getlocations  } from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import ImageUploaderWithCropper from '../../core/helper/ImageCropper/ImageUploaderWithCropper';
import { ENABLE_IMAGE_CROPPING } from '../../backend';

export default function AddIncidentReportAdmin() {

    // Kill switch based implementation for cropper image
    const image_cropper_enabled_prop = (ENABLE_IMAGE_CROPPING).toString().toLowerCase();
    const isImageCropperEnabled = image_cropper_enabled_prop==="true" ? true : false;

    const { admin, token } = isAuthenticated();

    const [values, setValues] = useState({
        driverNo: "",
        truckNo: "",
        otherTruckNo: "",
        trailerNo: "",
        otherTrailerNo: "",
        customer: "",
        incidentDate: "",
        incidentTime: "",
        incidentLocation: "",
        driveway: "",
        demolitionSite: false,
        incidentDetail: "",
        ifReported: false,
        reportHandlerName: "",
        reportHandlerContact: "",
        ifWitess: false,
        witnessName: "",
        witnessContact: "",
        comments: "",
        driverDate: moment().format('YYYY-MM-DD'),
        driverSignature: "",
        supervisorName: "",
        supervisorDate: "",
        supervisorSignature: "",
        drivers: "",
        partners: "",
        trucks: "",
        trailers: "",
        locations: "",
        photo1: "",
        photo2: "",
        photo3: "",
        photo4: "",
        isApproved: false,
        loading: false,
        isLoaded: "",
        error: "",
        CreatedIncidentReport: "",
        formData: "",
        didRedirect: false
    });

    // Cropper variables
    const ref1 = React.useRef();
    const ref2 = React.useRef();
    const ref3 = React.useRef();
    const ref4 = React.useRef();
    const [image1Uploaded, setImage1Uploaded] = useState(false);
    const [image2Uploaded, setImage2Uploaded] = useState(false);
    const [image3Uploaded, setImage3Uploaded] = useState(false);
    const [image4Uploaded, setImage4Uploaded] = useState(false);

    const [locationSearch, setLocationSearch] = useState("");
    const [filteredLocations, setFilteredLocations] = useState([]);

    const { driverNo, truckNo, otherTruckNo, trailerNo, otherTrailerNo, customer, incidentDate, incidentTime, incidentLocation, driveway, demolitionSite, 
        incidentDetail, ifReported, reportHandlerName, reportHandlerContact, ifWitess, witnessName, witnessContact, comments, driverDate, driverSignature, supervisorName, supervisorDate, supervisorSignature,
        drivers, partners, trucks, trailers, locations, CreatedIncidentReport, photo1, photo2, photo3, photo4, isApproved, loading, error, isLoaded, formData, didRedirect } = values;

        const preload = () => {
            setValues({ ...values, loading: true, isLoaded: ""})
            getmerchants(admin._id, token).then(data1 => {
                if (data1.error) {
                    setValues({ ...values, error: data1.error,  loading: "", isLoaded: ""});
                } else {
                    gettrucks(admin._id, token).then(data2 => {
                        if (data2.error) {
                            setValues({ ...values, error: data2.error, loading: "", isLoaded: "" });
                        } else {
                            gettrailers(admin._id, token).then(data3 => {
                                if (data3.error) {
                                    setValues({ ...values, error: data3.error, loading: "", isLoaded: "" });
                                } else {   
                                    getlocations(admin._id, token).then(data4 => {
                                        if (data4.error) {
                                            setValues({ ...values, error: data4.error, loading: "" });
                                        } else {
                                            getdrivers(admin._id, token).then(data5 => {
                                                if (data5.error) {
                                                    setValues({ ...values, error: data5.error, loading: "" })
                                                } else {
                                                    setValues({ 
                                                        ...values, 
                                                        partners: data1, 
                                                        trucks: data2, 
                                                        trailers: data3, 
                                                        locations: data4,
                                                        drivers: data5,
                                                        loading: "", 
                                                        isLoaded: true, 
                                                        formData: new FormData() 
                                                    });
                                                }
                                            })
                                            .catch(err => {
                                                console.log('Error loading the incident report form:', err);
                                                setValues({ ...values, error: "Error loading the incident report form", loading: "" });
                                                toastifyMessage("error", 'Error loading the incident report form');
                                            });
                                        }
                                    })
                                    .catch(err => {
                                        console.log('Error loading the incident report form:', err);
                                        setValues({ ...values, error: "Error loading the incident report form", loading: "" });
                                        toastifyMessage("error", 'Error loading the incident report form');
                                    });
                                }
                            })
                            .catch(err => {
                                console.log('Error loading the incident report form:', err);
                                setValues({ ...values, error: "Error loading the incident report form", loading: "" });
                                toastifyMessage("error", 'Error loading the incident report form');
                            });
                        }
                    })
                    .catch(err => {
                        console.log('Error loading the incident report form:', err);
                        setValues({ ...values, error: "Error loading the incident report form", loading: "" });
                        toastifyMessage("error", 'Error loading the incident report form');
                    });
                }
            })
            .catch(err => {
                console.log('Error loading the incident report form:', err);
                setValues({ ...values, error: "Error loading the incident report form", loading: "" });
                toastifyMessage("error", 'Error loading the incident report form');
            });
        };
        

    useEffect(() => {
        preload();
    }, []);

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: CreatedIncidentReport ? "" : "none" }}
        >
            <h4>{CreatedIncidentReport}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );
   
    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true, CreatedIncidentReport: "" });

        // Field validations
        const requiredFields = [driverNo, truckNo, trailerNo, customer, incidentDate, incidentTime, incidentLocation, driverDate, driverSignature];
        if (requiredFields.some(field => !field)) {
            if(!incidentLocation){
                setValues({ ...values, error: "Enter a valid incident location", loading: "", CreatedIncidentReport: '' });
                toastifyMessage("warning", "Enter a valid incident location");
                return;
            }
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedIncidentReport: '' });
            toastifyMessage("warning", 'All the fields marked with * are mandatory');
            return;
        }

        if(truckNo === "other" && (otherTruckNo === "")){ 
            setValues({ ...values, error: 'Enter valid truck number', loading: "", CreatedIncidentReport: '' });
            toastifyMessage("warning", 'Enter valid truck number');
            return;
        }

        if(trailerNo === "other" && (otherTrailerNo === "")){ 
            setValues({ ...values, error: 'Enter valid trailer number', loading: "", CreatedIncidentReport: '' });
            toastifyMessage("warning", 'Enter valid trailer number');
            return;
        }

        // Set the date field in the form in case the date is set to default
        if (!formData.get('driverDate')) {
            formData.set('driverDate', driverDate)
          }

        createincidentreport(admin._id, formData, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", CreatedIncidentReport: "" })
                    toastifyMessage("error", data.error);
                } else {
                    setValues({
                        ...values,
                        driverNo: "",
                        truckNo: "",
                        otherTruckNo: "",
                        trailerNo: "",
                        otherTrailerNo: "",
                        customer: "",
                        incidentDate: "",
                        incidentTime: "",
                        incidentLocation: "",
                        driveway: "",
                        demolitionSite: false,
                        incidentDetail: "",
                        ifReported: false,
                        reportHandlerName: "",
                        reportHandlerContact: "",
                        ifWitess: false,
                        witnessName: "",
                        witnessContact: "",
                        comments: "",
                        driverDate: moment().format('YYYY-MM-DD'),
                        driverSignature: "",
                        supervisorName: "",
                        supervisorDate: "",
                        supervisorSignature: "",
                        isApproved: false,
                        photo1: "",
                        photo2: "",
                        photo3: "",
                        photo4: "",
                        loading: "",
                        error: "",
                        CreatedIncidentReport: "Incident has been reported succesfully",
                        formData: new FormData(),
                        didRedirect: true
                    });
                    setLocationSearch("");

                    // Clear the cropper state
                    resetCropper();

                    setTimeout(() => {
                        toastifyMessage("success", 'Incident has been reported succesfully');
                    }, 200);
                }
            })
            .catch(err => {
                console.log('Error reporting the incident:', err);
                setValues({ ...values, error: "Error reporting the incident", loading: "", CreatedIncidentReport: "" });
                toastifyMessage("error", 'Error reporting the incident');
              });
    };

    const resetCropper = () => {
        if(isImageCropperEnabled){
            setImage1Uploaded(false);
            setImage2Uploaded(false);
            setImage3Uploaded(false);
            setImage4Uploaded(false);
        }
    };

    const handleSaveImage = (name, file) => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4")) ? file : "";
        setValues({ ...values, [name]: value })
        formData.set(name, value) 
    };

    const handleRemoveImage = (name) => {
        setValues({ ...values, [name]: "" })
        if(formData.get(name)){
            formData.delete(name);
        }
        if(name=="photo1"){
            ref1.current.value = "";
            setImage1Uploaded(false);
        }
        if(name=="photo2"){
            ref2.current.value = "";
            setImage2Uploaded(false);
        }
        if(name=="photo3"){
            ref3.current.value = "";
            setImage3Uploaded(false);
        }
        if(name=="photo4"){
            ref4.current.value = "";
            setImage4Uploaded(false);
        }
    };

    const handleChange = name => event => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4")) ? event.target.files[0] : event.target.value;
 
        if(((name === "trailerNo") || (name === "truckNo")) && ((value !== "other"))){
            if(name==="trailerNo"){
                setValues({ ...values, [name]: value, otherTrailerNo: "" })
                formData.set(name, value)
                if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")==="" ){
                    formData.delete("otherTrailerNo")
                }
            }
            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }
        }
        else if ((name === "ifReported") && (value === "false" || value === false)) {
            setValues({
              ...values,
              reportHandlerName: "",
              reportHandlerContact: "",
              [name]: value
            })
            formData.delete("reportHandlerName")
            formData.delete("reportHandlerContact")
            formData.set(name, value)
        }
        else if ((name === "ifWitess") && (value === "false" || value === false)) {
            setValues({
              ...values,
              witnessName: "",
              witnessContact: "",
              [name]: value
            })
            formData.delete("witnessName")
            formData.delete("witnessContact")
            formData.set(name, value)
        }
        else{
            setValues({ ...values, [name]: value })
            formData.set(name, value)
        }
    };

    const handleLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, incidentLocation: "" }));
            formData.delete("incidentLocation");
        }
    };

    const selectLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, incidentLocation: location.location }));
        setLocationSearch(location.location);
        formData.set("incidentLocation", location.location);
        setFilteredLocations([]);
    };

    const IncidentForm = () => (
        <form className="mt-4" style={{ display: (drivers !== "" || trucks !== "" || trailers !== "" || partners !== "" || locations !== "") ? "" : "none" }}>

            <div className="form-group">
                <label className="text-light"> Driver <span className="text-warning">*</span></label>
                <select
                  onChange={handleChange("driverNo")}
                  className="form-control"
                  placeholder="driverNo"
                >
                  <option value="" selected={driverNo===""}>Select the Driver</option>
                  {drivers && drivers.map((driver, index) => {
                    return (
                      <option key={index} value={driver._id}>
                        {driver.firstName + ' ' + driver.lastName }
                      </option>
                    )
                  })}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light"> Date of the Incident <span className="text-warning">*</span></label>
                <input className="form-control" type="date"
                    onChange={handleChange("incidentDate")}
                    name="incidentDate"
                    value={incidentDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Incident Time <span className="text-warning">*</span></label>
                <input className="form-control" type="time"
                    onChange={handleChange("incidentTime")}
                    name="incidentTime"
                    value={incidentTime}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("truckNo")}
                    className="form-control"
                    placeholder="Truck"
                >
                    <option value="" selected={truckNo===""}>Select the Truck</option>
                    {trucks && trucks.map((truck, index) => {
                        return (
                            <option key={index} value={truck._id}>
                                {truck.truckNumber}
                            </option>
                        )
                    })}
                    <option value="other" selected={truckNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Trailer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("trailerNo")}
                    className="form-control"
                    placeholder="Trailer"
                >
                    <option value="" selected={trailerNo===""}>Select the Trailer</option>
                    {trailers && trailers.map((trailer, index) => {
                        return (
                            <option key={index} value={trailer._id}>
                                {trailer.trailerNumber}
                            </option>
                        )
                    })}
                    <option value="other" selected={trailerNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Customer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("customer")}
                    className="form-control"
                    placeholder="Company"
                >
                    <option value="" selected={customer===""}>Select the Customer</option>
                    {partners && partners.map((partner, index) => {
                        return (
                            <option key={index} value={partner._id}>
                                {partner.partnerName}
                            </option>
                        )
                    })}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light">Exact Location of the incident <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Incident location"
                    value={locationSearch}
                    onChange={handleLocationSearch}
                />
                {locationSearch && (
                    <ul className="list-group mt-2">
                        {filteredLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="form-group">
                <label className="text-light"> Driveway </label>
                <input
                    onChange={handleChange("driveway")}
                    type="text"
                    className="form-control"
                    value={driveway}
                    name='driveway'
                    placeholder="Driveway"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Demolition Site <span className="text-warning">*</span></label>
                <select className="form-control" onChange={handleChange("demolitionSite")} value={demolitionSite}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>

            <div className="form-group">
                <label className="text-light"> Explain in detail how the incident/damage happened </label>
                <textarea
                  onChange={handleChange("incidentDetail")}
                  name="incidentDetail"
                  className="form-control"
                  placeholder="Incident or damage detail"
                  value={incidentDetail}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Was it reported <span className="text-warning">*</span></label>
                <select className="form-control"
                    value={ifReported}
                    onChange={handleChange("ifReported")}
                >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>

            {
                (ifReported==="true" || ifReported===true) &&
                <>
                    <div className="form-group">
                        <label className="text-light"> Reported To <span className="text-warning">*</span></label>
                        <input className="form-control" type="text"
                            onChange={handleChange("reportHandlerName")}
                            value={reportHandlerName}
                            name='reportHandlerName'
                            placeholder="Reported To"
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-light"> Reportee Phone Number </label>
                        <input className="form-control" type="number"
                            onChange={handleChange("reportHandlerContact")}
                            value={reportHandlerContact}
                            name='reportHandlerContact'
                            placeholder="Phone Number"
                        />
                    </div>
                </> 
            }

            <div className="form-group">
                <label className="text-light"> Were there any witness <span className="text-warning">*</span></label>
                <select className="form-control"
                        value={ifWitess}
                        onChange={handleChange("ifWitess")}
                >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>

            {
                (ifWitess==="true" || ifWitess===true) &&
                <>
                    <div className="form-group">
                        <label className="text-light"> Name of the Witness <span className="text-warning">*</span></label>
                        <input className="form-control" type="text"
                            onChange={handleChange("witnessName")}
                            value={witnessName}
                            name='witnessName'
                            placeholder="Witness Name"
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-light"> Witness Phone Number </label>
                        <input className="form-control" type="number"
                            onChange={handleChange("witnessContact")}
                            value={witnessContact}
                            name='witnessContact'
                            placeholder="Witness Phone Number"
                        />
                    </div>
                </> 
            }

            <div className="form-group">
                <label className="text-light"> Other Comments </label>
                <textarea className="form-control" name="comments"
                  onChange={handleChange("comments")}
                  placeholder="Comments"
                  value={comments}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Reporting Date <span className="text-warning">*</span></label>
                <input className="form-control" type="date"
                    onChange={handleChange("driverDate")}
                    name="driverDate"
                    value={driverDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Driver Signature <span className="text-warning">*</span></label>
                <input className="form-control" type="text"
                    onChange={handleChange("driverSignature")}
                    name="driverSignature"
                    value={driverSignature}
                    placeholder="Driver Signature"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Supervisor Name </label>
                <input className="form-control" type="text"
                    onChange={handleChange("supervisorName")}
                    name="supervisorName"
                    value={supervisorName}
                    placeholder="Supervisor Name"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Supervisor Date </label>
                <input className="form-control" type="date"
                    onChange={handleChange("supervisorDate")}
                    name="supervisorDate"
                    value={supervisorDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Supervisor Signature </label>
                <input className="form-control" type="text"
                    onChange={handleChange("supervisorSignature")}
                    name="supervisorSignature"
                    value={supervisorSignature}
                    placeholder="Supervisor Signature"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Approved </label>
                <select className="form-control"
                    value={isApproved}
                    onChange={handleChange("isApproved")}
                >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>

            <span className="text-white"> Incident Pictures </span>

            {isImageCropperEnabled ? 
                    <>
                        <ImageUploaderWithCropper fileInputRef={ref1} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo1" imageUploaded={image1Uploaded} setImageUploaded={setImage1Uploaded}/>
                        <ImageUploaderWithCropper fileInputRef={ref2} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo2" imageUploaded={image2Uploaded} setImageUploaded={setImage2Uploaded}/>
                        <ImageUploaderWithCropper fileInputRef={ref3} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo3" imageUploaded={image3Uploaded} setImageUploaded={setImage3Uploaded}/>
                        <ImageUploaderWithCropper fileInputRef={ref4} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo4" imageUploaded={image4Uploaded} setImageUploaded={setImage4Uploaded}/>
                    </>
                 :
                    <>
                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo1")}
                              type="file"
                              name="photo1"
                              accept="image/*"
                              placeholder="Choose an image"
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo2")}
                              type="file"
                              name="photo2"
                              accept="image/*"
                              placeholder="Choose an image"
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo3")}
                              type="file"
                              name="photo3"
                              accept="image/*"
                              placeholder="Choose an image"
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo4")}
                              type="file"
                              name="photo4"
                              accept="image/*"
                              placeholder="Choose an image"
                            />
                          </label>
                        </div>
                    </>
            }

            <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4 mt-3">
                Submit Report
            </button>

        </form>
    );

    return (
        <Base
            title="Incident Report Dashboard"
            description="Report an incident or damage"
            className="container bg-success p-4"
        >
            <Link to="/admin/dashboard" className="btn btn-md btn-dark mb-3">
                Admin Home
            </Link>

            <div className="row bg-dark text-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {isLoaded && IncidentForm()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                    <ToastifyMessage/> 
                </div>
            </div>
        </Base>
    );
}