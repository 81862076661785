import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { disAuthenticated } from "../../auth/helper/driverIndex";
import { Link, Redirect, useParams } from "react-router-dom";
import { createdropoffsheet, getpartners, getcommodities, getlocations } from "../helper/driverapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddPickTripSheet() {

    const { tripsheetId } = useParams();
    const { driver, token } = disAuthenticated();

    const [values, setValues] = useState({
        deliveryLocation: "",
        otherDeliveryLocation: "",
        deliveryCommodity: "",
        otherDeliveryCommodity: "",
        deliveryTimeIn: "",
        deliveryTimeOut: "",
        outBoundWeight: "",
        ticketNumberAtReceiving: "",
        deductions: "",
        receivingDate: moment().format('YYYY-MM-DD'),
        isDeliveryIncomplete: false,
        partners: "",
        commodities: "",
        locations: "",
        photo4: "",
        photo5: "",
        photo6: "",
        loading: false,
        isLoaded: "",
        error: "",
        CreatedDropTripSheet: "",
        formData: "",
        didRedirect: false
    });

    const [locationSearch, setLocationSearch] = useState("");
    const [showOtherLocation, setShowOtherLocation] = useState(false);
    const [filteredLocations, setFilteredLocations] = useState([]);

    const { deliveryLocation, otherDeliveryLocation, deliveryCommodity, otherDeliveryCommodity, deliveryTimeIn,
        deliveryTimeOut, outBoundWeight, ticketNumberAtReceiving, deductions, receivingDate, isDeliveryIncomplete, partners, commodities, locations, photo4, photo5, photo6,
        CreatedDropTripSheet, loading, isLoaded, error, formData, didRedirect } = values;

        const preload = () => {
            setValues({ ...values, loading: true, isLoaded: "" });
        
            getpartners(driver._id, token).then(data1 => {
                if (data1.error) {
                    setValues({ ...values, error: data1.error, loading: "", isLoaded: "" });
                } else {
                    getcommodities(driver._id, token).then(data2 => {
                        if (data2.error) {
                            setValues({ ...values, error: data2.error, loading: "", isLoaded: "" });
                        } else {
                            getlocations(driver._id, token).then(data3 => {
                                if (data3.error) {
                                    setValues({ ...values, error: data3.error, loading: "", isLoaded: "" });
                                } else {
                                    setValues({
                                        ...values,
                                        partners: data1,
                                        commodities: data2,
                                        locations: data3,
                                        loading: "",
                                        isLoaded: true,
                                        formData: new FormData()
                                    });
                                }
                            }).catch(err => {
                                console.log('Error loading the Trip sheet form:', err);
                                setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                                toastifyMessage("error", 'Error loading the Trip sheet form');
                            });
                        }
                    }).catch(err => {
                        console.log('Error loading the Trip sheet form:', err);
                        setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                        toastifyMessage("error", 'Error loading the Trip sheet form');
                    });
                }
            }).catch(err => {
                console.log('Error loading the Trip sheet form:', err);
                setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                toastifyMessage("error", 'Error loading the Trip sheet form');
            });
        };
        
    useEffect(() => {
        preload();
    }, []);

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: CreatedDropTripSheet ? "" : "none" }}
        >
            <h4>{CreatedDropTripSheet}</h4>
        </div>
    )

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    )

    const performRedirect = () => {
        if (didRedirect) {
            if (driver) {
                return (
                    <Redirect to="/driver/dashboard" />
                )
            }
        }
    }

    const onSubmit = (event) => {

        event.preventDefault();
        setValues({ ...values, error: "", loading: true, CreatedDropTripSheet: "" });

         // Field validations
         const requiredFields = [deliveryLocation, deliveryCommodity, deliveryTimeIn, deliveryTimeOut, outBoundWeight,ticketNumberAtReceiving, receivingDate];
         if (requiredFields.some(field => !field)) {
            if(!deliveryLocation){
                setValues({ ...values, error: "Enter a valid location", loading: "", CreatedDropTripSheet: '' });
                toastifyMessage("warning", "Enter a valid location");
                return;
            }
                setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedDropTripSheet: '' });
                toastifyMessage("warning", "All the fields marked with * are mandatory");
                return;
         }

        if(deliveryCommodity === "other" && (otherDeliveryCommodity === "")){ 
            setValues({ ...values, error: 'Enter valid commodity name', loading: "", CreatedDropTripSheet: '' });
            toastifyMessage("warning", 'Enter valid commodity name');
            return;
        }

        if(deliveryLocation === "other" && (otherDeliveryLocation === "")){ 
            setValues({ ...values, error: 'Enter valid delivery location', loading: "", CreatedDropTripSheet: '' });
            toastifyMessage("warning", 'Enter valid delivery location');
            return;
        }

         // Set the receiving date field in the form in case the date is set to default
        if (!formData.get('receivingDate')) {
            formData.set('receivingDate', receivingDate)
        }

        createdropoffsheet(tripsheetId, formData, driver._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", CreatedDropTripSheet: "" })
                        toastifyMessage("error", data.error);
                } else {
                    setValues({
                        ...values,
                        deliveryLocation: "",
                        otherDeliveryLocation: "",
                        deliveryCommodity: "",
                        otherDeliveryCommodity: "",
                        deliveryTimeIn: "",
                        deliveryTimeOut: "",
                        outBoundWeight: "",
                        ticketNumberAtReceiving: "",
                        isDeliveryIncomplete: false,
                        photo4: "",
                        photo5: "",
                        photo6: "",
                        deductions: "",
                        receivingDate: "",
                        loading: "",
                        error: "",
                        CreatedDropTripSheet: "Drop Trip Sheet created successfully",
                        formData: new FormData(),
                        didRedirect: true
                    });
                  
                    setTimeout(() => {
                        toastifyMessage("success", "Drop Trip Sheet created successfully");
                    }, 200);
                }
            })
            .catch(err => {
                console.log('Error creating the Trip sheet:', err);
                setValues({ ...values, error: "Error creating the Trip sheet", loading: "", CreatedDropTripSheet: ""});
                toastifyMessage("error", 'Error creating the Trip sheet');
            });
    };

    const handleChange = name => event => {
        const value = ((name === "photo4") || (name === "photo5") || (name === "photo6")) ? event.target.files[0] : event.target.value;

        if(((name === "deliveryCommodity") || (name === "deliveryLocation")) && ((value !== "other"))){          
  
            if(name==="deliveryCommodity"){
                setValues({ ...values, [name]: value, otherDeliveryCommodity: "" })
                formData.set(name, value)
                if(formData.get("otherDeliveryCommodity") || formData.get("otherDeliveryCommodity")===""){
                    formData.delete("otherDeliveryCommodity")
                }
            }

            if(name==="deliveryLocation"){
                setValues({ ...values, [name]: value, otherDeliveryLocation: "" })
                formData.set(name, value)
                if(formData.get("otherDeliveryLocation") || formData.get("otherDeliveryLocation")==="" ){
                    formData.delete("otherDeliveryLocation")
                }
            }
        }
        else{
            setValues({ ...values, [name]: value })
            formData.set(name, value)
        }
    };

    const handleLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, deliveryLocation: "" }));
            formData.delete("deliveryLocation");
        }
    };

        // if (searchTerm.toLowerCase() === "other") {
        //     setShowOtherLocation(true);
        //     setValues(prevValues => ({ ...prevValues, deliveryLocation: "other" }));
        //     formData.set("deliveryLocation", "other");
        // } else {
        //     setShowOtherLocation(false);
        //     const filtered = locations.filter(location =>
        //         location.location.toLowerCase().includes(searchTerm.toLowerCase())
        //     );
        //     setFilteredLocations(filtered);

        //     const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        //     if (exactMatch) {
        //         selectLocation(exactMatch);
        //     } else {
        //         setValues(prevValues => ({ ...prevValues, deliveryLocation: "" }));
        //         formData.delete("deliveryLocation");
        //     }
        // }

    const selectLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, deliveryLocation: location.location }));
        setLocationSearch(location.location);
        formData.set("deliveryLocation", location.location);
        setFilteredLocations([]);
    };

    // const handleOtherLocationChange = (event) => {
    //     const value = event.target.value;
    //     setValues(prevValues => ({ ...prevValues, otherDeliveryLocation: value }));
    //     formData.set("otherDeliveryLocation", value);
    // };

    const dropOffForm = () => (
        <form className="mt-4" style={{ display: (partners !== "" || commodities !== "" || locations !=="") ? "" : "none" }}>

            <div className="form-group">
                <label className="text-light"> Load Rejected <span className="text-warning">*</span></label>
                <select
                  onChange={handleChange("isDeliveryIncomplete")}
                  className="form-control"
                >
                  <option value={false} selected={isDeliveryIncomplete===false}>No</option>
                  <option value={true}>Yes</option>
                </select>
            </div>
            
            <div className="form-group">
                <label className="text-light">End Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a location"
                    value={locationSearch}
                    onChange={handleLocationSearch}
                />
                {locationSearch && (
                    <ul className="list-group mt-2">
                        {filteredLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* {showOtherLocation && (
                <div className="form-group">
                    <label className="text-light">Other End Location <span className="text-warning">*</span></label>
                    <input
                        onChange={handleOtherLocationChange}
                        className="form-control"
                        placeholder="Enter custom location"
                        value={values.otherDeliveryLocation || ''}
                    />
                </div>
            )} */}
       
            <div className="form-group">
                <label className="text-light"> Commodity <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("deliveryCommodity")}
                    className="form-control"
                    placeholder="Commodity"
                    required
                >
                    <option value="" selected={deliveryCommodity===""}>Select the Commodity</option>
                    {commodities && commodities.map((commodity, index) => {
                        return (
                            <option key={index} value={commodity._id}>
                                {commodity.commodityName}
                            </option>
                        )
                    })}
                    <option value="other" selected={deliveryCommodity==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( deliveryCommodity && (deliveryCommodity === "other")) ? "" : "none" }}>
                <label className="text-light">Other Commodity <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherDeliveryCommodity')}
                    className="form-control"
                    placeholder="Commodity"
                    value={otherDeliveryCommodity} />
            </div>

            <div className="form-group">
                <label className="text-light"> Receiving Date <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("receivingDate")}
                    type="date"
                    name="receivingDate"
                    className="form-control"
                    value={receivingDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Scale In <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("deliveryTimeIn")}
                    type="time"
                    className="form-control"
                    name="deliveryTimeIn"
                    value={deliveryTimeIn}
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Scale Out <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("deliveryTimeOut")}
                    type="time"
                    className="form-control"
                    value={deliveryTimeOut}
                    name='deliveryTimeOut'
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Outbound Weigh (in lbs) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("outBoundWeight")}
                    type="number"
                    className="form-control"
                    value={outBoundWeight}
                    name='outBoundWeight'
                    placeholder="Weight (in lbs)"
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Ticket Number At Delivery <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("ticketNumberAtReceiving")}
                    type="text"
                    className="form-control"
                    value={ticketNumberAtReceiving}
                    name='ticketNumberAtReceiving'
                    placeholder="Ticket Number"
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Deductions </label>
                <input
                    onChange={handleChange("deductions")}
                    type="text"
                    className="form-control"
                    value={deductions}
                    name='deductions'
                    placeholder="Deductions"
                    required
                />
            </div>

            <span className="text-white"> Trip Photos </span>

            <div className="form-group">
              <label className="btn btn-block btn-success rounded">
                <input
                  onChange={handleChange("photo4")}
                  type="file"
                  name="photo4"
                  accept="image/*"
                  placeholder="Choose an image for the trip sheet"
                />
              </label>
            </div>

            <div className="form-group">
              <label className="btn btn-block btn-success rounded">
                <input
                  onChange={handleChange("photo5")}
                  type="file"
                  name="photo5"
                  accept="image/*"
                  placeholder="Choose an image for the trip sheet"
                />
              </label>
            </div>

            <div className="form-group">
              <label className="btn btn-block btn-success rounded">
                <input
                  onChange={handleChange("photo6")}
                  type="file"
                  name="photo6"
                  accept="image/*"
                  placeholder="Choose an image for the trip sheet"
                />
              </label>
            </div>

            <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4 mt-3">
                Submit Load
            </button>

        </form>
    );

    return (
        <Base
            title="Drop Trip Sheet Dashboard"
            description="Add drop trip sheet"
            className="container bg-success p-4"
        >
            <Link to="/driver/dashboard" className="btn btn-md btn-dark mb-3">
                Driver Home
            </Link>

            <div className="row bg-dark text-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {isLoaded && dropOffForm()}
                    {performRedirect()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                    <ToastifyMessage/> 
                </div>
            </div>
        </Base>
    );
}
