import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { disAuthenticated } from "../../auth/helper/driverIndex";
import { Link, Redirect } from "react-router-dom"
import { createpickupsheet, getpartners, gettrucks, gettrailers, getcommodities,getlocations  } from "../helper/driverapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddPickTripSheet() {
    const { driver, token } = disAuthenticated();

    const [values, setValues] = useState({
        truckNo: "",
        otherTruckNo: "",
        trailerNo: "",
        otherTrailerNo: "",
        pickUpLocation: "",
        otherPickUpLocation: "",
        pickUpCompany: "",
        otherPickUpCompany: "",
        pickUpCommodity: "",
        otherPickUpCommodity: "",
        pickUpTimeIn: "",
        pickUpTimeOut: "",
        inBoundWeight: "",
        ticketNumberAtShipping: "",
        fuelRate: "",
        comments: "",
        date: moment().format('YYYY-MM-DD'),
        partners: "",
        trucks: "",
        trailers: "",
        commodities: "",
        locations: "",
        photo1: "",
        photo2: "",
        photo3: "",
        loading: false,
        isLoaded: "",
        error: "",
        CreatedNoLoadSheet: "",
        formData: "",
        didRedirect: false
    });

    const [locationSearch, setLocationSearch] = useState("");
    const [showOtherLocation, setShowOtherLocation] = useState(false);
    const [filteredLocations, setFilteredLocations] = useState([]);

    const { truckNo, otherTruckNo, trailerNo, otherTrailerNo, pickUpLocation, otherPickUpLocation, pickUpCompany, otherPickUpCompany, pickUpCommodity, otherPickUpCommodity,
        pickUpTimeIn, pickUpTimeOut, inBoundWeight, ticketNumberAtShipping, fuelRate, comments, date, partners, commodities, trucks,
        trailers, locations, CreatedNoLoadSheet, photo1, photo2, photo3, loading, error, isLoaded, formData, didRedirect } = values;

        const preload = () => {
            setValues({ ...values, loading: true, isLoaded: ""})
            getpartners(driver._id, token).then(data1 => {
                if (data1.error) {
                    setValues({ ...values, error: data1.error,  loading: "", isLoaded: ""});
                } else {
                    gettrucks(driver._id, token).then(data2 => {
                        if (data2.error) {
                            setValues({ ...values, error: data2.error, loading: "", isLoaded: "" });
                        } else {
                            gettrailers(driver._id, token).then(data3 => {
                                if (data3.error) {
                                    setValues({ ...values, error: data3.error, loading: "", isLoaded: "" });
                                } else {
                                    getcommodities(driver._id, token).then(data4 => {
                                        if (data4.error) {
                                            setValues({ ...values, error: data4.error });
                                        } else {
                                            getlocations(driver._id, token).then(data5 => {
                                                if (data5.error) {
                                                    setValues({ ...values, error: data5.error });
                                                } else {
                                                    setValues({ 
                                                        ...values, 
                                                        partners: data1, 
                                                        trucks: data2, 
                                                        trailers: data3, 
                                                        commodities: data4,
                                                        locations: data5,
                                                        loading: "", 
                                                        isLoaded: true, 
                                                        formData: new FormData() 
                                                    });
                                                }
                                            })
                                            .catch(err => {
                                                console.log('Error loading the No Load sheet form:', err);
                                                setValues({ ...values, error: "Error loading the No Load sheet form", loading: "" });
                                                toastifyMessage("error", 'Error loading the No Load sheet form');
                                            });
                                        }
                                    })
                                    .catch(err => {
                                        console.log('Error loading the No Load sheet form:', err);
                                        setValues({ ...values, error: "Error loading the No Load sheet form", loading: "" });
                                        toastifyMessage("error", 'Error loading the No Load sheet form');
                                    });
                                }
                            })
                            .catch(err => {
                                console.log('Error loading the No Load sheet form:', err);
                                setValues({ ...values, error: "Error loading the No Load sheet form", loading: "" });
                                toastifyMessage("error", 'Error loading the No Load sheet form');
                            });
                        }
                    })
                    .catch(err => {
                        console.log('Error loading the No Load sheet form:', err);
                        setValues({ ...values, error: "Error loading the No Load sheet form", loading: "" });
                        toastifyMessage("error", 'Error loading the No Load sheet form');
                    });
                }
            })
            .catch(err => {
                console.log('Error loading the No Load sheet form:', err);
                setValues({ ...values, error: "Error loading the No Load sheet form", loading: "" });
                toastifyMessage("error", 'Error loading the No Load sheet form');
            });
        };
        

    useEffect(() => {
        preload();
    }, []);

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: CreatedNoLoadSheet ? "" : "none" }}
        >
            <h4>{CreatedNoLoadSheet}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );
   
    const performRedirect = () => {
        if (didRedirect) {
            if (driver) {
                return (
                    <Redirect to="/driver/dashboard" />
                )
            }

        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true, CreatedNoLoadSheet: "" });

        // Field validations
        const requiredFields = [truckNo, trailerNo, pickUpLocation, pickUpCompany, pickUpCommodity, pickUpTimeIn, pickUpTimeOut, inBoundWeight, ticketNumberAtShipping, date];
        if (requiredFields.some(field => !field)) {
            if(!pickUpLocation){
                setValues({ ...values, error: "Enter a valid location", loading: "", CreatedNoLoadSheet: '' });
                toastifyMessage("warning", "Enter a valid location");
                return;
            }
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedNoLoadSheet: '' });
            toastifyMessage("warning", 'All the fields marked with * are mandatory');
            return;
        }

        if(truckNo === "other" && (otherTruckNo === "")){ 
            setValues({ ...values, error: 'Enter valid truck number', loading: "", CreatedNoLoadSheet: '' });
            toastifyMessage("warning", 'Enter valid truck number');
            return;
        }

        if(trailerNo === "other" && (otherTrailerNo === "")){ 
            setValues({ ...values, error: 'Enter valid trailer number', loading: "", CreatedNoLoadSheet: '' });
            toastifyMessage("warning", 'Enter valid trailer number');
            return;
        }

        if(pickUpCompany === "other" && (otherPickUpCompany === "")){ 
            setValues({ ...values, error: 'Enter valid customer', loading: "", CreatedNoLoadSheet: '' });
            toastifyMessage("warning", 'Enter valid customer');
            return;
        }

        if(pickUpCommodity === "other" && (otherPickUpCommodity === "")){ 
            setValues({ ...values, error: 'Enter valid commodity name', loading: "", CreatedNoLoadSheet: '' });
            toastifyMessage("warning", 'Enter valid commodity name');
            return;
        }

        if(pickUpLocation === "other" && (otherPickUpLocation === "")){ 
            setValues({ ...values, error: 'Enter valid pickup location', loading: "", CreatedNoLoadSheet: '' });
            toastifyMessage("warning", 'Enter valid pickup location');
            return;
        }

        // Set the driver for the trip sheet
        formData.set('driverNo', driver._id);

        // Set Fuel Rate for the sheet
        if(fuelRate){
            formData.set('fuelRate', fuelRate);
        }

        // Mark the sheet as complete
        if (!formData.get('isIncompleteFlag')) {
            formData.set('isIncompleteFlag', false)
        }

        // Mark as no load
        // 311 maps to no Load in typeOfLoad
        formData.set('typeOfLoad', 311);

        // Set the date field in the form in case the date is set to default
        if (!formData.get('date')) {
            formData.set('date', date)
          }

        createpickupsheet(driver._id, formData, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", CreatedNoLoadSheet: "" })
                    toastifyMessage("error", data.error);
                } else {
                    // Redirect to Driver Dashboard
                    setValues({
                        ...values,
                        truckNo: "",
                        otherTruckNo: "",
                        trailerNo: "",
                        otherTrailerNo: "",
                        date: "",
                        pickUpLocation: "",
                        otherPickUpLocation: "",
                        pickUpCompany: "",
                        otherPickUpCompany: "",
                        pickUpCommodity: "",
                        otherPickUpCommodity: "",
                        pickUpTimeIn: "",
                        pickUpTimeOut: "",
                        inBoundWeight: "",
                        ticketNumberAtShipping: "",
                        comments: "",
                        photo1: "",
                        photo2: "",
                        photo3: "",
                        loading: "",
                        error: "",
                        CreatedNoLoadSheet: "No Load sheet created succesfully",
                        formData: new FormData(),
                        didRedirect: true
                    });

                    setTimeout(() => {
                        toastifyMessage("success", 'No Load sheet created successfully');
                    }, 200);
                }
            })
            .catch(err => {
                console.log('Error creating the No Load sheet form:', err);
                setValues({ ...values, error: "Error creating the No Load sheet form", loading: "", CreatedNoLoadSheet: "" });
                toastifyMessage("error", 'Error creating the No Load sheet form');
              });
    };

    const handleChange = name => event => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3")) ? event.target.files[0] : event.target.value;
 
        if(((name === "trailerNo") || (name === "truckNo") || (name === "pickUpCompany") || (name === "pickUpCommodity") || (name === "pickUpLocation")) && ((value !== "other"))){
            if(name==="trailerNo"){
                setValues({ ...values, [name]: value, otherTrailerNo: "" })
                formData.set(name, value)
                if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")==="" ){
                    formData.delete("otherTrailerNo")
                }
            }

            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }

            if(name==="pickUpCompany"){
                let p = partners.find(partner => partner._id === value);
                let fr = "";
                if(p && p.fuelRate){
                    fr = p.fuelRate;
                }
                setValues({ ...values, [name]: value, otherPickUpCompany:"", fuelRate: fr});
                formData.set(name, value)
                if(formData.get("otherPickUpCompany") || formData.get("otherPickUpCompany")===""){
                    formData.delete("otherPickUpCompany")
                }
            }

            if(name==="pickUpCommodity"){
                setValues({ ...values, [name]: value, otherPickUpCommodity: "" })
                formData.set(name, value)
                if(formData.get("otherPickUpCommodity") || formData.get("otherPickUpCommodity")===""){
                    formData.delete("otherPickUpCommodity")
                }
            }

            if(name==="pickUpLocation"){
                setValues({ ...values, [name]: value, otherPickUpLocation: "" })
                formData.set(name, value)
                if(formData.get("otherPickUpLocation") || formData.get("otherPickUpLocation")===""){
                    formData.delete("otherPickUpLocation")
                }
            }
        }
        else{
            if(name === 'pickUpCompany' && value === "other"){
                setValues({ ...values, [name]: value });
                formData.set(name, value);
            }
            else{
                setValues({ ...values, [name]: value })
                formData.set(name, value)
            }
        }
    };

    const handleLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, pickUpLocation: "" }));
            formData.delete("pickUpLocation");
        }
    };

    const selectLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, pickUpLocation: location.location }));
        setLocationSearch(location.location);
        formData.set("pickUpLocation", location.location);
        setFilteredLocations([]);
    };

    const pickUpForm = () => (
        <form className="mt-4" style={{ display: (trucks !== "" || trailers !== "" || partners !== "" || commodities !== ""  || locations !== "") ? "" : "none" }}>

            <div className="form-group">
                <label className="text-light"> Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("truckNo")}
                    className="form-control"
                    placeholder="Truck"
                >
                    <option value="" selected={truckNo===""}>Select the Truck</option>
                    {trucks && trucks.map((truck, index) => {
                        return (
                            <option key={index} value={truck._id}>
                                {truck.truckNumber}
                            </option>
                        )
                    })}
                    <option value="other" selected={truckNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Trailer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("trailerNo")}
                    className="form-control"
                    placeholder="Trailer"
                >
                    <option value="" selected={trailerNo===""}>Select the Trailer</option>
                    {trailers && trailers.map((trailer, index) => {
                        return (
                            <option key={index} value={trailer._id}>
                                {trailer.trailerNumber}
                            </option>
                        )
                    })}
                    <option value="other" selected={trailerNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Customer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("pickUpCompany")}
                    className="form-control"
                    placeholder="Company"
                >
                    <option value="" selected={pickUpCompany===""}>Select the Customer</option>
                    {partners && partners.map((partner, index) => {
                        return (
                            <option key={index} value={partner._id}>
                                {partner.partnerName}
                            </option>
                        )
                    })}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light">Pickup Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a location"
                    value={locationSearch}
                    onChange={handleLocationSearch}
                />
                {locationSearch && (
                    <ul className="list-group mt-2">
                        {filteredLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="form-group">
                <label className="text-light"> Commodity <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("pickUpCommodity")}
                    className="form-control"
                    placeholder="Commodity"
                >
                    <option value="" selected={pickUpCommodity===""}>Select the Commodity</option>
                    {commodities && commodities.map((commodity, index) => {
                        return (
                            <option key={index} value={commodity._id}>
                                {commodity.commodityName}
                            </option>
                        )
                    })}
                    <option value="other" selected={pickUpCommodity==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( pickUpCommodity && (pickUpCommodity === "other")) ? "" : "none" }}>
                <label className="text-light">Other Commodity <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherPickUpCommodity')}
                    className="form-control"
                    placeholder="Commodity"
                    value={otherPickUpCommodity} />
            </div>

            <div className="form-group">
                <label className="text-light"> Shipping Date <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("date")}
                    type="date"
                    name="date"
                    className="form-control"
                    value={date}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Scale In <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("pickUpTimeIn")}
                    type="time"
                    className="form-control"
                    name="pickUpTimeIn"
                    value={pickUpTimeIn}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Scale Out <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("pickUpTimeOut")}
                    type="time"
                    className="form-control"
                    value={pickUpTimeOut}
                    name='pickUpTimeOut'
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Net Weight (in lbs) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("inBoundWeight")}
                    type="number"
                    className="form-control"
                    value={inBoundWeight}
                    name='inBoundWeight'
                     placeholder="Weight (in lbs)"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Ticket Number <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("ticketNumberAtShipping")}
                    type="text"
                    className="form-control"
                    value={ticketNumberAtShipping}
                    name='ticketNumberAtShipping'
                    placeholder="Ticket Number"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Comments </label>
                <textarea
                            onChange={handleChange("comments")}
                            name={comments}
                            className="form-control"
                            type="string"
                            placeholder="Comments"
                            value={comments}
                />
            </div>

            <span className="text-white"> Trip Photos </span>

            <div className="form-group">
              <label className="btn btn-block btn-success rounded">
                <input
                  onChange={handleChange("photo1")}
                  type="file"
                  name="photo1"
                  accept="image/*"
                  placeholder="Choose an image for the trip sheet"
                />
              </label>
            </div>

            <div className="form-group">
              <label className="btn btn-block btn-success rounded">
                <input
                  onChange={handleChange("photo2")}
                  type="file"
                  name="photo2"
                  accept="image/*"
                  placeholder="Choose an image for the trip sheet"
                />
              </label>
            </div>

            <div className="form-group">
              <label className="btn btn-block btn-success rounded">
                <input
                  onChange={handleChange("photo3")}
                  type="file"
                  name="photo3"
                  accept="image/*"
                  placeholder="Choose an image for the trip sheet"
                />
              </label>
            </div>

            <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4 mt-3">
                Submit No Load
            </button>

        </form>
    );

    return (
        <Base
            title="No Load Sheet Dashboard"
            description="Add No Load sheet"
            className="container bg-success p-4"
        >
            <Link to="/driver/dashboard" className="btn btn-md btn-dark mb-3">
                Driver Home
            </Link>

            <div className="row bg-dark text-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {isLoaded && pickUpForm()}
                    {performRedirect()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                    <ToastifyMessage/> 
                </div>
            </div>
        </Base>
    );
}