import React from 'react'
import { Modal, Button } from "react-bootstrap";
import LightLoaderScreen from '../../core/helper/LightLoaderScreen';
 
export default function DeleteConfirmation({ showModal, hideModal, confirmDeleteModal, id, message, loadingModal=false, title="Delete Confirmation", action="Delete" }) {
    return (
        <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => confirmDeleteModal(id) }>
            {action}
          </Button>
        </Modal.Footer>
        {LightLoaderScreen(loadingModal)}
      </Modal>
    )
}
