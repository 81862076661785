import React, {useState, useEffect} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link,useParams} from "react-router-dom";
import {resetNightoutRate} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';import { toast } from 'react-toastify';
import moment from 'moment';

export default function UpdateNightOutRate() {

    const {admin, token} = isAuthenticated()
    
    const [values, setValues] = useState({
        effectiveDate: moment().format('YYYY-MM-DD'),
        newNightOutRate : "",
        error: false,
        updatedRate: "",
        loading: false,
    });
    
    const { effectiveDate, newNightOutRate , error, updatedRate, loading } = values

    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, updatedRate: "", loading: true})

        // Field validations
        const requiredFields = [effectiveDate, newNightOutRate];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedRate: ""});
            return;
        }

        resetNightoutRate({effectiveDate, newNightOutRate}, admin._id, token)
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", updatedRate: ""})
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        ...data,                 
                        loading: "",
                        error: "",
                        updatedRate: "Night out rate updated succesfully"
                    });
                    toastifyMessage("success", 'Night out rate updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the night out rate:', err);
                setValues({ ...values, error: "Error updating the night out rate", loading: "" });
                toastifyMessage("error", 'Error updating the night out rate');
            });  

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedRate ? "" : "none" }}
        >
            <h4>{updatedRate}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    const updateRateForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-dark"> Effective Date <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("effectiveDate")}
                    type="date"
                    name="effectiveDate"
                    value={effectiveDate}
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Night out Rate </label>
                <input
                    className="form-control"
                    onChange={handleChange("newNightOutRate")}
                    type="number"
                    name="newNightOutRate"
                    value={newNightOutRate}
                    placeholder="Night out rate"
                />
            </div>
            <button 
                onClick ={onSubmit}
                className="btn btn-outline-info"> Update Rate
            </button>
        </form>
    )

    return (
        <Base
        title="Nightout Rate Dashboard"
        description="Edit the night out rate"
        className="container bg-info p-4"
        >

            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                {updateRateForm()}
                {successMessage()}
                {warningMessage()}
                
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage/>
        </Base>
    )
}