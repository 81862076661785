import React, { useState, useEffect } from 'react'
import Base from "../../core/Base"
import { Link } from "react-router-dom"
import moment from 'moment';
import { createdriver, getcards } from "../helper/adminapicall"
import { isAuthenticated } from "../../auth/helper/adminIndex"
import LoadingScreen from '../../core/helper/LoadingScreen'
import ToastifyMessage from '../../core/helper/ToastifyMessage'
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddDriver() {

  const { admin, token } = isAuthenticated()

  const [values, setValues] = useState({
    username: "",
    password: "",
    cnfpassword: '',
    email: "",
    contact: "",
    firstName: "",
    lastName: "",
    driverNo: "",
    licenceNo: "",
    licenceExpiryDate: "",
    legalStatus: "",
    legalStatusExpiryDate: "",
    americanImmigrationDocumentExpiryDate: "",
    state: "",
    country: "",
    streetAddress: "",
    pincode: "",
    fuelCard: "",
    businessName: "",
    businessAddress: "",
    businessTaxNumber: "",
    ratePerHour: "",
    fuelCards: "",
    driverPhoto: "",
    licencePhoto: "",
    loading: false,
    isLoaded: "",
    error: "",
    CreatedDriver: "",
    getaRedirect: false,
    formData: new FormData()
  });

  const ref1 = React.useRef();
  const ref2 = React.useRef();

  const { username, password, cnfpassword, firstName, lastName, driverNo, licenceNo, licenceExpiryDate, legalStatus, legalStatusExpiryDate, americanImmigrationDocumentExpiryDate, city, state, country, streetAddress, pincode, contact,
    fuelCard, businessName, businessAddress, businessTaxNumber, ratePerHour, fuelCards, driverPhoto, licencePhoto, email, loading, isLoaded, error, CreatedDriver, getaRedirect, formData } = values;

    const preload = () => {
      setValues({ ...values, loading: true, isLoaded: ""})
      getcards(admin._id, token).then(data1 => {
          if (data1.error) {
              setValues({ ...values, error: data1, loading: "" })
          } else {
            setValues({
              ...values,
              fuelCards: data1?data1:[],
              loading: "",
              isLoaded: true,
              formData: new FormData()
            });
          }
      })
      .catch(err => {
        console.log('Error loading the driver onboarding form:', err);
        setValues({ ...values, error: "Error loading the driver onboarding form", loading: "" });
        toastifyMessage("error", 'Error loading the driver onboarding form');
      });
  }

  useEffect(() => {
    preload();
}, []);

  const successMessage = () => (
    <div className="alert alert-success mt-3"
      style={{ display: CreatedDriver ? "" : "none" }}
    >
      <h4>{CreatedDriver} created successfully </h4>
    </div>
  )

  const warningMessage = () => (
    <div className="alert alert-danger mt-3"
      style={{ display: error ? "" : "none" }}
    >
      <h4>{error} </h4>
    </div>
  )

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true, CreatedDriver: "" })

    // Password validation
    if (password !== cnfpassword) {
      setValues({ ...values, error: 'Passwords do not match', CreatedDriver: "" });
      toastifyMessage("warning", "Passwords do not match");
      return;
    }

    // Field validations
    const requiredFields = [username, password, cnfpassword, firstName, lastName, licenceNo, city, state, country, streetAddress, pincode, contact, email, fuelCard, businessName, businessAddress, businessTaxNumber, ratePerHour];
    if (requiredFields.some(field => !field)) {
        setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedDriver: ""});
        toastifyMessage("warning", "All the fields marked with * are mandatory");
        return;
    }
    
    createdriver(formData, admin._id, token)
      .then(data => {
        if (data.error) {
          setValues({ ...values, error: data.error, CreatedDriver: "", loading: false })
          toastifyMessage("error", data.error);
        } else {
          setValues({
            ...values,
            firstName: "",
            lastName: "",
            driverNo: "",
            licenceNo: "",
            licenceExpiryDate: "",
            legalStatus: "",
            legalStatusExpiryDate: "",
            americanImmigrationDocumentExpiryDate: "",
            city: "",
            state: "",
            country: "",
            streetAddress: "",
            pincode: "",
            contact: "",
            driverPhoto: "",
            licencePhoto: "",
            fuelCard: "",
            businessName: "",
            businessAddress: "",
            businessTaxNumber: "",
            ratePerHour: "",
            email: "",
            username: "",
            password: "",
            cnfpassword: "",
            loading: false,
            error: "",
            CreatedDriver: data.firstName + " " + data.lastName,
            formData: new FormData()
          })
          ref1.current.value = "";
          ref2.current.value = "";
          toastifyMessage("success", "Driver onboarded successfully");
        }
      }

      )
      .catch(err => {
        console.log('Error onboarding the driver:', err);
        setValues({ ...values, error: "Error onboarding the driver", loading: "" });
        toastifyMessage("error", 'Error onboarding the driver');
      });
  }

  const handleChange = name => event => {
    let value = ((name === "driverPhoto") || (name === "licencePhoto")) ? event.target.files[0] : event.target.value;

    // Validate input for fields that should not contain digits
    if (name === 'firstName' || name === 'lastName' || name === 'city' || name === 'state' || name === 'country') {
      if (!/^[a-zA-Z]*$/.test(value)) {
        return;
      }
    }
    formData.set(name, value)
    setValues({ ...values, [name]: value })
  };

  const createDriverForm = () => (
    <form className="mt-4">
      <div className="form-group">
        <label className="text-light"> First Name <span className="text-warning">*</span> </label>
        <input
          onChange={handleChange("firstName")}
          name="firstName"
          className="form-control"
          placeholder="First Name"
          value={firstName}
          type="text"
          required
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Last Name <span className="text-warning">*</span> </label>
        <input
          onChange={handleChange("lastName")}
          name="lastName"
          className="form-control"
          placeholder="Last Name"
          value={lastName}
          type="text"
          required
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Driver Licence <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("licenceNo")}
          name="licenceNo"
          className="form-control"
          placeholder="Driver Licence"
          value={licenceNo}
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Driver Licence Expiry Date </label>
        <input
          onChange={handleChange("licenceExpiryDate")}
          type="date"
          name="licenceExpiryDate"
          className="form-control"
          value={licenceExpiryDate}
          min={moment().format('YYYY-MM-DD')}
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Legal Status </label>
        <input
          onChange={handleChange("legalStatus")}
          name="legalStatus"
          className="form-control"
          placeholder='Legal Status'
          value={legalStatus}
          type="text"
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Legal Status Expiry Date </label>
        <input
          onChange={handleChange("legalStatusExpiryDate")}
          type="date"
          name="legalStatusExpiryDate"
          className="form-control"
          value={legalStatusExpiryDate}
          min={moment().format('YYYY-MM-DD')}
        />
      </div>
      <div className="form-group">
        <label className="text-light"> US Immigration Document Expiry Date </label>
        <input
          onChange={handleChange("americanImmigrationDocumentExpiryDate")}
          type="date"
          name="americanImmigrationDocumentExpiryDate"
          className="form-control"
          value={americanImmigrationDocumentExpiryDate}
          min={moment().format('YYYY-MM-DD')}
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Street Address <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("streetAddress")}
          name="streetAddress"
          className="form-control"
          placeholder="Street Address"
          value={streetAddress}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> City <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("city")}
          name="city"
          className="form-control"
          placeholder="City"
          value={city}
          type="text"
          required
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Province <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("state")}
          name="state"
          className="form-control"
          placeholder="State"
          value={state}
          type="text"
          required
        />
      </div>
      <div className="form-group">
        <label className="text-light"> Country <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("country")}
          name="country"
          className="form-control"
          placeholder="Country"
          value={country}
          type="text"
          title="Please enter alphabets only"
          required
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Postal Code <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("pincode")}
          name="pincode"
          className="form-control"
          placeholder="Postal Code"
          value={pincode}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Contact <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("contact")}
          name="contact"
          className="form-control"
          placeholder="Contact"
          value={contact}
          type="number"
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Email <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("email")}
          name="email"
          className="form-control"
          placeholder="Email"
          value={email}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Business Name <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("businessName")}
          name="businessName"
          className="form-control"
          placeholder="Business Name"
          value={businessName}
          type="text"
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Business Address <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("businessAddress")}
          name="businessAddress"
          className="form-control"
          placeholder="Business Address"
          value={businessAddress}
          type="text"
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Business Tax Number <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("businessTaxNumber")}
          name="businessTaxNumber"
          className="form-control"
          placeholder="Business Tax Number"
          value={businessTaxNumber}
          type="text"
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Hourly Rate <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("ratePerHour")}
          name="ratePerHour"
          className="form-control"
          placeholder="Hourly Rate"
          value={ratePerHour}
          type="number"
        />
      </div>

      <div className="form-group">
                <label className="text-light"> Fuel Card <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('fuelCard')}
                    className="form-control"
                    placeholder="fuelCard"
                >
                    <option value="" selected={fuelCard===""}>Select the Fuel Card</option>
                    {fuelCards &&
                        fuelCards.map((card, index) => (
                            <option key={index} value={card._id}>
                                {card.cardNo}
                            </option>
                        ))}
                </select>
      </div>

      <div className="form-group">
        <label className="text-light"> Username <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("username")}
          name="username"
          className="form-control"
          placeholder="Username"
          value={username}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Password <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("password")}
          name="password"
          className="form-control"
          placeholder="Password"
          value={password}
          type="password"
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Confirm Password <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("cnfpassword")}
          name="cnfpassword"
          className="form-control"
          placeholder="Confirm Password"
          value={cnfpassword}
          type="text"
        />
      </div>

      <span className="text-white">Driver Photo</span>
      <div className="form-group">
        <label className="btn btn-block btn-success">
          <input
            onChange={handleChange("driverPhoto")}
            type="file"
            name="driverPhoto"
            accept="image"
            placeholder="Choose a photo for the Driver"
            ref={ref1}
          />
        </label>
      </div>

      <span className="text-white">Licence Photo</span>
      <div className="form-group">
        <label className="btn btn-block btn-success">
          <input
            onChange={handleChange("licencePhoto")}
            type="file"
            name="licencePhoto"
            accept="image"
            placeholder="Choose a photo for Driver's Licence"
            ref={ref2}
          />
        </label>
      </div>
      

      <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4">
        Add Driver
      </button>
    </form>
  );





  return (
    <Base
      title="Driver Dashboard"
      description="Onboard a new driver"
      className="container bg-success p-4"
    >

      <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3">
        Admin Home
      </Link>

      <div className="row bg-dark test-white rounded center">
        <div className="col-md-8 offset-md-1 col-sm-8 offset-sm-2 col-10 offset-1 ">
          {isLoaded && createDriverForm()}
          {successMessage()}
          {warningMessage()}
          {LoadingScreen(loading)}
          <ToastifyMessage/>
        </div>
      </div>

    </Base>
  )
}