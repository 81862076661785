import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getmerchants, deletemerchant } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import { Modal, Button, Container, Row, Col} from 'react-bootstrap';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManagePartners() {
    const { admin, token } = isAuthenticated();
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState("");
    const [error, setError] = useState('');

    // Partner Search
    const [partnerSearch, setPartnerSearch] = useState("");
    const [filteredPartners, setFilteredPartners] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const preload = () => {
        setLoading(true);
        setError('');
        getmerchants(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the customers. Please try again later.');
                } else {
                    setPartners(data);
                    setFilteredPartners(data);
                    if(data.length===0){
                        toastifyMessage("error", 'No customer found');
                    }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the customers:', err);
                setError('Error fetching the customers');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the customers');
            });
    };

    // For Delete confirmation modal
    const [showPartnerDeleteMessage, setShowPartnerDeleteMessage] = useState(false);
    const [deletePartnerMessage, setDeletePartnerMessage] = useState("Are you sure you want to offboard the customer?");
    const [deletePartnerId, setDeletePartnerId] = useState("");
    const handleCancelPartnerDeletion = () => setShowPartnerDeleteMessage(false)
    const handleShowPartnerDeleteMessage = () => setShowPartnerDeleteMessage(true)

    const deleteThisPartner = (partnerId) => {
        setLoadingModal(true);
        deletemerchant(partnerId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the customer');
                } else {
                    toastifyMessage("success", 'Customer deleted successfully');
                   
                    // To close the modal
                    handleCancelPartnerDeletion();
                    setLoadingModal("");
                
                    // Reload the values
                    setPartners(partners.filter(partner => (partner._id !== partnerId)));
                    setFilteredPartners(partners.filter(partner => (partner._id !== partnerId)));
                }
            })
            .catch(err => {
                console.log('Error deleting the cusomer:', err);
                setError('Error deleting the customer');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the customer');
            });
    };

    const handlePartnerSearch = (event) => {
        setPartnerSearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredPartners(partners);
            return;
        }
        const filtered = partners.filter(partner =>
            (partner.partnerName).toLowerCase().includes(searchTerm)
        );
        setFilteredPartners(filtered);
    };

    return (
        <Base title="Customer Dashboard" description="Manage/Edit/Delete the customers">
            <Link className="btn btn-info rounded" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>Customers</h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for a customer"
                                value={partnerSearch}
                                onChange={handlePartnerSearch}
                            />
                        </div>
                        <div>
                            {filteredPartners.map((partner, index) => (
                                <div key={index} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title text-left" style={{ fontFamily: 'Englebert', color: 'black' }}>
                                            {partner.partnerName}
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-success rounded mb-2"
                                                    to={`/admin/update/partner/${partner._id}`}
                                                >
                                                    Update
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeletePartnerId(partner._id)
                                                            handleShowPartnerDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-info rounded mb-2"
                                                    to={`/admin/customer/${partner._id}/view/invoice?partnerName=${encodeURIComponent(partner.partnerName)}`}
                                                >
                                                    Invoice
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-dark rounded mb-2"
                                                    to={`/admin/customer/${partner._id}/update/rates?customer=${partner.partnerName}`}
                                                >
                                                    Trip Rates
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link
                                                    className="btn btn-dark rounded mb-2"
                                                    to={`/admin/customer/${partner._id}/view/triprates?customer=${partner.partnerName}`}
                                                >
                                                    Rate History
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <DeleteConfirmation showModal={showPartnerDeleteMessage} hideModal={handleCancelPartnerDeletion} confirmDeleteModal={deleteThisPartner} id={deletePartnerId} message={deletePartnerMessage} loadingModal={loadingModal} />
            <ToastifyMessage/> 
        </Base>
    );
}
