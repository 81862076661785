import { API } from "../../backend"

// Log Sheet 

// Add a log sheet
export const createlogsheet = (driverid, loadDetails, token) => {
    return fetch(`${API}/driver/logsheet/create/${driverid}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: loadDetails

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// get flag of log sheet for a driver
export const getincompletelogsheet = (driverId, token) => {
    return fetch(`${API}/driver/incomplete/logSheet/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

// get a log sheet
export const getlogsheet = (logSheetId, driverId, token) => {
    return fetch(`${API}/driver/logSheet/${logSheetId}/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// get all driver log sheets
export const getalldriverlogsheets = (driverId, token) => {
    return fetch(`${API}/driver/logSheets/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};


// delete a log sheet
export const deletelogsheet = (logSheetId, driverId, token) => {
    return fetch(`${API}/driver/logsheet/delete/${driverId}/${logSheetId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


// update a log sheet
export const updatelogsheet = (logSheetId, logDetails, driverId, token) => {
    return fetch(`${API}/driver/logSheet/update/${driverId}/${logSheetId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: logDetails

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Drop log sheet photo
export const droplogsheetphoto = (logSheetId, logDetails, driverId, token) => {
    return fetch (`${API}/driver/logSheet/drop/photo/${logSheetId}/${driverId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(logDetails)

    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// Trip Sheets

// get incomplete trip sheet
export const getincompletetripsheet = (driverId, token) => {
    return fetch(`${API}/driver/incomplete/tripSheet/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

// Add a Pick Up trip sheet
export const createpickupsheet = (driverId, loadDetails, token) => {
    return fetch(`${API}/driver/tripsheet/pickup/create/${driverId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: loadDetails

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Add a Drop Up trip sheet
export const createdropoffsheet = (tripSheetId, loadDetails, driverId, token) => {
    return fetch(`${API}/driver/tripsheet/dropoff/create/${driverId}/${tripSheetId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: loadDetails

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// get all driver trip sheets
export const getalldrivertripsheets = (driverId, token) => {
    return fetch(`${API}/driver/tripSheets/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// get a trip sheet
export const gettripsheet = (tripsheetId, driverId, token) => {
    return fetch(`${API}/driver/tripSheet/${tripsheetId}/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// update a trip sheet
export const updatetripsheet = (tripSheetId, logDetails, driverId, token) => {
    return fetch(`${API}/driver/tripSheet/update/${driverId}/${tripSheetId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: logDetails

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

  // delete a trip sheet
  export const deletetripsheet = (tripSheetId, driverId, token) => {
    return fetch(`${API}/driver/tripSheet/delete/${driverId}/${tripSheetId}`,{
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
         }
      
      })
      .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
}

// Drop trip sheet photo
export const droptripsheetphoto = (tripSheetId, tripSheetDetails, driverId, token) => {
    return fetch (`${API}/driver/tripsheet/drop/photo/${tripSheetId}/${driverId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(tripSheetDetails)

    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// Fuel log sheets

// Add a fuel log sheet
export const createfuellogsheet = (driverId, fuelLogDetails, token) => {
    return fetch(`${API}/driver/fuelLogSheet/create/${driverId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: fuelLogDetails
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// get a fuel log sheet
export const getfuellogsheet = (fuelLogSheetId, driverId, token) => {
    return fetch(`${API}/driver/fuelLogSheet/${fuelLogSheetId}/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// get all fuel log sheets
// This snippet for is returning all the fuel sheets for all the drivers
export const getallfuellogsheets = (token) => {
    return fetch(`${API}/driver/allFuelLogSheets/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// get fuel log sheets of a particular driver
// This snippet is for returning all the fuel sheets for a particular driver
export const getalldriverfuellogsheets = (driverId, token) => {
    return fetch(`${API}/driver/fuelLogSheets/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Delete a fuel log sheet
export const deletefuellogsheet = (fuelLogSheetId, driverId, token) => {
    return fetch(`${API}/driver/fuelLogSheet/delete/${driverId}/${fuelLogSheetId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Update a fuel log sheet
export const updatefuellogsheet = (fuelLogSheetId, fuelLogDetails, driverId, token) => {
    return fetch(`${API}/driver/fuelLogSheet/update/${driverId}/${fuelLogSheetId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: fuelLogDetails
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// Driver APIs with date filtering
// Fuel Log Sheet
export const getFuelLogSheetsForDriver = (driverId, filterDate, token) => {
    return fetch(`${API}/driver/fuelLogSheet/datefilter/${driverId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
};

// Drop fuel sheet photo
export const dropfuelsheetphoto = (fuelLogSheetId, fuelLogDetails, driverId, token) => {
    return fetch (`${API}/driver/fuelLogSheet/drop/photo/${fuelLogSheetId}/${driverId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
       },
    body: JSON.stringify(fuelLogDetails)

    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

// Log Sheet 
export const getlogsheetsfordriver = (driverId, filterDate, token) => {
    return fetch(`${API}/driver/logSheet/datefilter/${driverId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };

// Trip sheets
export const gettripsheetsfordriver = (driverId, filterDate, token) => {
    return fetch(`${API}/driver/tripSheet/datefilter/${driverId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
         body: JSON.stringify(filterDate)      
      })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
  };


// Driver Call

// get a driver
export const getdriver = (driverId, token) => {
    return fetch(`${API}/driver/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {

            return response.json();
        })
        .catch(err => console.log(err));
};


// update a driver

export const updatedriver = (driverId, token, driver) => {
    return fetch(`${API}/driver/${driverId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: driver

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


// get All Trucks
export const gettrucks = (driverId, token) => {
    return fetch(`${API}/driver/trucks/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// get All Clients
export const getpartners = (driverId, token) => {
    return fetch(`${API}/driver/partners/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// get All Trailers
export const gettrailers = (driverId, token) => {
    return fetch(`${API}/driver/trailers/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// get All Cards
export const getcards = (driverId, token) => {
    return fetch(`${API}/driver/cards/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// get All Commodities
export const getcommodities = (driverId, token) => {
    return fetch(`${API}/driver/commodities/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// get All Location
export const getlocations = (driverId, token) => {
    return fetch(`${API}/driver/locations/${driverId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();

        })
        .catch(err => console.log(err));
};

// Create an incident report
export const createincidentreport = (driverId, incidentDetails, token) => {
    return fetch(`${API}/driver/report/incident/create/${driverId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        },
        body: incidentDetails

    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};