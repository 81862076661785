import React, {useState} from 'react'
import Base from "../../core/Base"
import { isAuthenticated } from "../../auth/helper/adminIndex"
import {Link} from "react-router-dom";
import {createlocation} from "../helper/adminapicall"
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toast } from 'react-toastify';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddLocation() {

    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        location: "",
        error: false,
        createdLocation: "",
        loading: false,
        didRedirect: false
    });

    const { location, error, createdLocation, loading, didRedirect } = values

    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, createdLocation: "", loading: true})

        // Field validations
        const requiredFields = [location];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'Location is mandatory', loading: "", createdLocation: ""});
            toastifyMessage("warning", 'Location is mandatory');
            return;
        }

        createlocation(admin._id, token, {location})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", createdLocation: "" });
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        location: "",                       
                        loading: "",
                        error: "",
                        createdLocation: "Location added succesfully"
                    });
                    toastifyMessage("success", 'Location added successfully');
                }
            })
            .catch(err => {
                console.log('Error adding the location:', err);
                setValues({ ...values, error: "Error adding the location", loading: "" });
                toastifyMessage("error", 'Error adding the location');
              });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: createdLocation ? "" : "none" }}
        >
            <h4>{createdLocation}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );

    const createLocationForm = () => (
        <>
            <form className="mt-4">
                <div className="form-group">
                    <label className="text-light"> Enter the Location <span className="text-info">*</span></label>
                    <input type="text"
                    className="form-control"
                    onChange={handleChange("location")}
                    value={location}
                    placeholder="Location"
                    autoFocus
                    required
                    />
                </div>
                    <button 
                        onClick ={onSubmit}
                        className="btn btn-outline-info"> Add Location 
                    </button>
            </form>
            {LoadingScreen(loading)}
        </>
    )

    return (
        <Base
        title="Location Dashboard"
        description="Add a new Location"
        className="container bg-info p-4"
        >
           {returnToAdminDashboard()}
            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                    {createLocationForm()}
                    {successMessage()}
                    {warningMessage()}
                
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    )
}
