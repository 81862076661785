import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import { Link, useLocation, useParams } from 'react-router-dom';
import {createlocationmetric, getlocations} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import moment from 'moment';

export default function AddLocationMetrics() {

    // Extracting URL parameters
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const customerName = query.get('customer');
    const { partnerId } = useParams();

    const {admin, token} = isAuthenticated()
    const [values, setValues] = useState({
        locations: "",
        effectiveDate: moment().format('YYYY-MM-DD'),
        location1: "",
        location2: "",
        ratePerUnit: "",
        loadSubTotal: "",
        customer: "",
        ifFlatRate: false,
        error: false,
        createdLocationMetric: "",
        loading: false
    });
    
    const { locations, effectiveDate, location1, location2, ratePerUnit, loadSubTotal, customer, ifFlatRate, createdLocationMetric, loading, error } = values

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getlocations(admin._id, token).then(data => {
                    if(data.error) {
                        setValues({ ...values, error: data.error });
                    } 
                    else {
                        setValues({ 
                            ...values, 
                            locations: data,
                            customer: partnerId,
                            loading: ""
                        });
                    }     
                })
                .catch(err => {
                    console.log('Error loading the locations:', err);
                    setValues({ ...values, error: "Error loading the locations", loading: "" });
                    toastifyMessage("error", 'Error loading the locations');
                });
        }
    
useEffect(() => {
    preload();
}, []);

    const returnToAdminDashboard = () => (
        <div className="mt-3">
            <Link className="btn btn-sm btn-dark mb-3 ml-3" to = "/admin/dashboard">
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        const value = name === 'ifFlatRate' ? event.target.checked : event.target.value;
        if(name === 'ifFlatRate'){
            setValues({ ...values, [name]: value, ratePerUnit: "", loadSubTotal: ""});
        }
        else{
            setValues({ ...values, [name]: value });
        }
    };

    // Pickup location search
    const [pickupLocationSearch, setPickupLocationSearch] = useState("");
    const [filteredPickupLocations, setFilteredPickupLocations] = useState([]);
    
    const handlePickupLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setPickupLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPickupLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectPickupLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, location1: "" }));
        }
    };

    const selectPickupLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, location1: location.location }));
        setPickupLocationSearch(location.location);
        setFilteredPickupLocations([]);
    };

    // Delivery location search
    const [deliveryLocationSearch, setDeliveryLocationSearch] = useState("");
    const [filteredDeliveryLocations, setFilteredDeliveryLocations] = useState([]);

    const handleDeliveryLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setDeliveryLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDeliveryLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectDeliveryLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, location2: "" }));
        }
    };

    const selectDeliveryLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, location2: location.location }));
        setDeliveryLocationSearch(location.location);
        setFilteredDeliveryLocations([]);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, createdLocationMetric: "", loading: true})

        // Field validations
        const requiredFields = [location1, location2];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", createdLocationMetric: ""});
            toastifyMessage("warning", 'All the fields marked with * are mandatory');
            return;
        }

        if(ifFlatRate){
            if(!loadSubTotal){
              setValues({ ...values, error: 'Enter valid load subtotal', loadingModal: "", updatedLoad: '' });
              toastifyMessage("warning", 'Enter valid load subtotal');
              return;
            }
          }
  
          else if(!ifFlatRate){
            if(!ratePerUnit){
              setValues({ ...values, error: 'Enter valid rate per unit', loadingModal: "", updatedLoad: '' });
              toastifyMessage("warning", 'Enter valid rate per unit');
              return;
            }
          }

        createlocationmetric(admin._id, token, {effectiveDate, location1, location2, ratePerUnit, loadSubTotal, customer, ifFlatRate})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", createdLocationMetric: "" });
                    toastifyMessage("warning", data.error);
                }
                else{
                    setValues({
                        ...values,
                        location1: "",
                        location2: "",
                        ratePerUnit: "",
                        loadSubTotal: "",
                        ifFlatRate: false,                     
                        loading: "",
                        error: "",
                        createdLocationMetric: "Trip rates added successfully"
                    });
                    setPickupLocationSearch("");
                    setDeliveryLocationSearch("");
                    toastifyMessage("success", "Trip rates added succesfully");
                }
            })
            .catch(err => {
                console.log('Error adding the trip rates:', err);
                setValues({ ...values, error: "Error adding the trip rates", loading: "" });
                toastifyMessage("error", 'Error adding the trip rates');
              });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: createdLocationMetric ? "" : "none" }}
        >
            <h4>{createdLocationMetric}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const createLocationMetricsForm = () => (
        <>
            <form className="mt-4">
                <div className="form-group d-flex align-items-center">
                  <label className="text-light" style={{ marginRight: '10px', marginBottom: '0' }}>
                    Flat Rate
                  </label>
                  <input
                    type="checkbox"
                    checked={ifFlatRate}
                    onChange={handleChange("ifFlatRate")}
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <div className="form-group">
                    <label className="text-light"> Effective Date <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("effectiveDate")}
                        type="date"
                        value={effectiveDate}
                        name="effectiveDate"
                    />
                </div>

                <div className="form-group">
                    <label className="text-light">Pickup Location<span className="text-warning">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search for a location"
                        value={pickupLocationSearch}
                        onChange={handlePickupLocationSearch}
                    />
                    {pickupLocationSearch && (
                        <ul className="list-group mt-2">
                            {filteredPickupLocations.map((location, index) => (
                                <li
                                    key={index}
                                    className="list-group-item list-group-item-action"
                                    onClick={() => selectPickupLocation(location)}
                                >
                                    {location.location}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="form-group">
                    <label className="text-light">Delivery Location<span className="text-warning">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search for a location"
                        value={deliveryLocationSearch}
                        onChange={handleDeliveryLocationSearch}
                    />
                    {deliveryLocationSearch && (
                        <ul className="list-group mt-2">
                            {filteredDeliveryLocations.map((location, index) => (
                                <li
                                    key={index}
                                    className="list-group-item list-group-item-action"
                                    onClick={() => selectDeliveryLocation(location)}
                                >
                                    {location.location}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {!ifFlatRate && (
                    <div className="form-group">
                        <label className="text-light"> Rate Per Unit </label>
                        <input
                            className="form-control"
                            onChange={handleChange("ratePerUnit")}
                            type="text"
                            value={ratePerUnit}
                            name="ratePerUnit"
                            placeholder="Rate Per Unit"
                        />
                    </div>
                )}
                {ifFlatRate && (
                    <div className="form-group">
                        <label className="text-light"> Load Sub Total </label>
                        <input
                            className="form-control"
                            onChange={handleChange("loadSubTotal")}
                            type="text"
                            value={loadSubTotal}
                            name="loadSubTotal"
                            placeholder="Load Sub Total"
                        />
                    </div>
                )}
                                                      
                <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                    Add Trip Rates
                </button>
            </form>
            {LoadingScreen(loading)}
        </>
    )

    return (
        <Base
            title="Trip Rate Dashboard"
            description="Add rates for the trips"
            className="container bg-info p-4"
        >
            <div className="text-center text-dark">
                <h1 style={{fontWeight: 'bold',textTransform: 'uppercase'}}>
                  {customerName}
                </h1>
            </div>
            {returnToAdminDashboard()}

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                    {createLocationMetricsForm()}
                    {successMessage()}
                    {warningMessage()}
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    )
}