import React, {useState, useEffect} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link,useParams} from "react-router-dom";
import {gettruck, updatetruck} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import {PROVINCES} from '../../core/Constants';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function UpdateTruck() {

    const {truckId} = useParams()
    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        truckNumber: "",
        vehicleIdentificationNumber: "",
        plateNumber: "",
        province: "",
        error: false,
        updatedTruck: "",
        loading: false,
        isLoaded: "",
        didRedirect: false
    });
    
    const { truckNumber, vehicleIdentificationNumber, plateNumber, province, error, updatedTruck, loading, isLoaded, didRedirect } = values

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        gettruck(truckId, admin._id, token).then(data => {
          if(data.error){
            setValues({ ...values, loading: "", isLoaded: "", error: data.error })
          } else{
            setValues({
                ...values,
                ...data,
                error: "",
                loading: "",
                isLoaded: true
            });
            }
        })
        .catch(err => {
            console.log('Error loading the truck:', err);
            setValues({ ...values, error: "Error loading the truck", loading: "" });
            toastifyMessage("error", 'Error loading the truck');
        });  
      }
  
    useEffect(() => {
        preload()
      },[])


    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, updatedTruck: "", loading:true})

        // Field validations
        const requiredFields = [truckNumber, vehicleIdentificationNumber, plateNumber, province];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedTruck: ""});
            return;
        }

        updatetruck(truckId, token, admin._id, {truckNumber, vehicleIdentificationNumber, plateNumber, province})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", updatedTruck: ""})
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        ...data,                       
                        loading: "",
                        error: "",
                        updatedTruck: "Truck updated succesfully"
                    });
                    toastifyMessage("success", 'Truck updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the truck:', err);
                setValues({ ...values, error: "Error updating the truck", loading: "" });
                toastifyMessage("error", 'Error updating the truck');
            });  

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedTruck ? "" : "none" }}
        >
            <h4>{updatedTruck}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const updateTruckForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-dark"> Truck Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("truckNumber")}
                    type="text"
                    value={truckNumber}
                    placeholder={truckNumber}
                    autoFocus
                    required
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Vehicle Identification Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("vehicleIdentificationNumber")}
                    type="text"
                    value={vehicleIdentificationNumber}
                    name="vehicleIdentificationNumber"
                    placeholder={vehicleIdentificationNumber}
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Plate Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("plateNumber")}
                    type="text"
                    value={plateNumber}
                    name="plateNumber"
                    placeholder={plateNumber}
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Province <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('province')}
                    className="form-control"
                    placeholder="province"
                >
                    {PROVINCES &&
                        PROVINCES.map((p, index) => (
                            <option key={index} value={p} selected={p===province}>
                                {p}
                            </option>
                    ))}
                </select>
            </div>

            <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                Update Truck 
            </button>
        </form>
    )

    
    return (
        <Base
        title="Truck Dashboard"
        description="Update the Truck Number"
        className="container bg-info p-4"
        >
            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                {isLoaded && updateTruckForm()}
                {successMessage()}
                {warningMessage()}
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage/>
        </Base>
    )
}
