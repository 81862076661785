import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { disAuthenticated } from "../../auth/helper/driverIndex";
import { Link } from "react-router-dom";
import { createfuellogsheet, getdriver, getcards, gettrucks } from "../helper/driverapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function AddFuelLogSheet() {
    const { driver, token } = disAuthenticated();

    const [values, setValues] = useState({
        cardNo: "",
        otherCardNo: "",
        truckNo: "",
        otherTruckNo: "",
        refuellingDate: moment().format('YYYY-MM-DD'),
        odometer: "",
        def: "",
        fuel: "",
        location: "",
        cards: "",
        trucks: "",
        loading: true,
        isLoaded: "",
        error: "",
        CreatedFuelLogSheet: "",
        getaRedirect: false,
        formData: "",
        fuelPhoto1: "",
        fuelPhoto2: ""
    });

    const ref1 = React.useRef();
    const ref2 = React.useRef();

    const { cardNo, otherCardNo, truckNo, otherTruckNo, refuellingDate, odometer, def, fuel, location, fuelPhoto1, fuelPhoto2, cards, trucks, loading, isLoaded, error, CreatedFuelLogSheet, getaRedirect, formData } = values;

    // Method to preload Trucks and Cards
    const preload = () => {
        setValues({ ...values, loading: true })
        getdriver(driver._id, token).then(data1 => {
            if (data1.error) {
                setValues({ ...values, error: data1.error, loading: "" })
            } else {
                gettrucks(driver._id, token).then(data2 => {
                    if (data2.error) {
                        setValues({ ...values, error: data2.error, loading: "" })
                    } else {
                        setValues({ ...values, cards: data1.fuelCard, trucks: data2, formData: new FormData(), loading: "", isLoaded: true})
                    }
                })
                .catch(err => {
                    console.log('Error loading the Fuel sheet form:', err);
                    setValues({ ...values, error: "Error loading the Fuel sheet form", loading: "" });
                    toastifyMessage("error", 'Error loading the Fuel sheet form');
                });
            }
        })
        .catch(err => {
            console.log('Error loading the Fuel sheet form:', err);
            setValues({ ...values, error: "Error loading the Fuel sheet form", loading: "" });
            toastifyMessage("error", 'Error loading the Fuel sheet form');
        });
    }

    useEffect(() => {
        preload();
    }, [])

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: CreatedFuelLogSheet ? "" : "none" }}
        >
            <h4>{CreatedFuelLogSheet}</h4>
        </div>
    )

    const warningMessage = () => (
        <div className="alert alert-danger mt-3" style={{ display: error ? "" : "none" }}>
            <h4> {error} </h4>
        </div>
    )

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true, CreatedFuelLogSheet: "" })

        // Field Validation
        const requiredFields = [truckNo, cardNo, refuellingDate, odometer, def, fuel, location];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedFuelLogSheet: '' });
            toastifyMessage("warning", 'All the fields marked with * are mandatory');
            return;
        }

        if(cardNo === "other" && (otherCardNo === "")){ 
            setValues({ ...values, error: 'Enter valid card number', loading: "", CreatedFuelLogSheet: '' });
            toastifyMessage("warning", 'Enter valid card number');
            return;
        }

        if(truckNo === "other" && (otherTruckNo === "")){ 
            setValues({ ...values, error: 'Enter valid truck number', loading: "", CreatedFuelLogSheet: '' });
            toastifyMessage("warning", 'Enter valid truck number');
            return;
        }

        if(!formData.get('refuellingDate')){
            formData.set('refuellingDate', refuellingDate)
        }
        formData.set('driverNo', driver._id)
        createfuellogsheet(driver._id, formData, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", CreatedFuelLogSheet: ""});
                    toastifyMessage("error", data.error);
                } else {
                    setValues({
                        ...values,
                        cardNo: "",
                        otherCardNo: "",
                        truckNo: "",
                        otherTruckNo: "",
                        refuellingDate: "",
                        odometer: "",
                        def: "",
                        fuel: "",
                        location: "",
                        loading: "",
                        error: "",
                        fuelPhoto1: "",
                        fuelPhoto2: "",
                        CreatedFuelLogSheet: "Fuel Sheet created successfully",
                        formData: new FormData()
                    })
                    ref1.current.value = "";
                    ref2.current.value = "";
                    toastifyMessage("success", 'Fuel Sheet created successfully');
                }
            })
            .catch(err => {
                console.log('Error creating the fuel sheet:', err);
                setValues({ ...values, error: "Error creating the fuel sheet", loading: "", CreatedFuelLogSheet: "" });
                toastifyMessage("error", 'Error creating the fuel sheet');
            });
    }

    const handleChange = name => event => {
        const value = ((name === "fuelPhoto1") || (name === "fuelPhoto2")) ? event.target.files[0] : event.target.value
        
        if(((name === "cardNo") || (name === "truckNo")) && (value !== "other")){
            if(name==="cardNo"){
                setValues({ ...values, [name]: value, otherCardNo: "" })
                formData.set(name, value)
                if(formData.get("otherCardNo") || formData.get("otherCardNo")===""){
                    formData.delete("otherCardNo")
                }
            }

            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }
        }
        else{
            setValues({ ...values, [name]: value })
            formData.set(name, value)
        }
    };

    const createFuelLogSheetForm = () => (
        <form className="mt-4">

            <div className="form-group">
                <label className="text-light"> Card Number <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("cardNo")}
                    className="form-control"
                    placeholder="Card"
                >
                    <option value="" selected={cardNo===""}>Select the Card</option>
                    {cards &&
                        (
                            <option value={cards._id}>
                                {cards.cardNo}
                            </option>
                        )
                    }
                    <option value="other" selected={cardNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( cardNo && (cardNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Card <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherCardNo')}
                    className="form-control"
                    placeholder="Card number"
                    value={otherCardNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('truckNo')}
                    className="form-control"
                    placeholder="Truck"
                >
                    <option value="" selected={truckNo===""}>Select the Truck</option>
                    {trucks &&
                        trucks.map((truck, index) => (
                            <option key={index} value={truck._id}>
                                {truck.truckNumber}
                            </option>
                        ))}
                    <option value="other" selected={truckNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Refuelling Date <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('refuellingDate')}
                    type="date"
                    className="form-control"
                    value={refuellingDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Odometer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('odometer')}
                    type="number"
                    className="form-control"
                    placeholder="Ex. 30000"
                    value={odometer}
                />
            </div>

            <div className="form-group">
                <label className="text-light">DEF (in Litres)<span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('def')}
                    type="number"
                    className="form-control"
                    placeholder="Ex. 100"
                    value={def}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Fuel (in Litres) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('fuel')}
                    type="number"
                    className="form-control"
                    placeholder="Ex. 150"
                    value={fuel} />
            </div>

            <div className="form-group">
                <label className="text-light">Location <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('location')}
                    className="form-control"
                    placeholder="Ex. Brampton"
                    value={location} />
            </div>

            <span className="text-white"> Refuelling Photos </span>
      
            <div className="form-group">
                <label className="btn btn-block btn-success rounded">
                    <input
                        onChange={handleChange("fuelPhoto1")}
                        type="file"
                        name="fuelPhoto1"
                        accept="image/*"
                        placeholder="Choose an Image for refuelling"
                        ref={ref1}
                    />
                </label>
            </div>
      
            <div className="form-group">
                <label className="btn btn-block btn-success rounded">
                    <input
                        onChange={handleChange("fuelPhoto2")}
                        type="file"
                        name="fuelPhoto2"
                        accept="image/*"
                        placeholder="Choose an Image for refuelling"
                        id="photoInput"
                        ref={ref2}
                    />
                </label>
            </div>

            <button
                type="submit"
                onClick={onSubmit}
                className="btn btn-outline-success mb-4 mt-3">
                Submit Fuel Log
            </button>
        </form>
    );

    return (
        <Base
            title="Fuel Sheet Dashboard"
            description="Add fuel sheet"
            className="container bg-success p-4"
        >
            <Link to="/driver/dashboard" className="btn brn-md btn-dark mb-3">
                Driver Home
            </Link>

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {isLoaded && createFuelLogSheetForm()}
                    {LoadingScreen(loading)}
                    {successMessage()}
                    {warningMessage()}
                    <ToastifyMessage/> 
                </div>
            </div>
        </Base>
    )
}