import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getdrivers, deletedriver, deactivateDriver } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function ManageDrivers() {
    const { admin, token } = isAuthenticated();
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState("");
    const [error, setError] = useState('');

    // Driver Search
    const [driverSearch, setDriverSearch] = useState("");
    const [filteredDrivers, setFilteredDrivers] = useState([]);

    useEffect(() => {
        preload();
    }, []);

    const preload = () => {
        setLoading(true);
        setError('');
        getdrivers(admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error fetching the drivers. Please try again later.');
                } else {
                    setDrivers(data);
                    setFilteredDrivers(data);
                    if(data.length===0){
                        toastifyMessage("warning", 'No driver found');
                        }
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching the drivers:', err);
                setError('Error fetching the drivers');
                setLoading(false);
                toastifyMessage("error", 'Error fetching the drivers');
            });
    };

     // For Delete confirmation modal
     const [showDeleteMessage, setShowDeleteMessage] = useState(false);
     const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to offboard the driver?");
     const [deleteDriverId, setDeleteDriverId] = useState("");
     const handleCancelDeletion = () => setShowDeleteMessage(false)
     const handleShowDeleteMessage = () => setShowDeleteMessage(true)

    const deleteThisDriver = (driverId) => {
        setLoadingModal(true);
        deletedriver(driverId, token, admin._id)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error deleting the driver');
                } else {
                    toastifyMessage("success", 'Driver deleted successfully');
      
                    // To close the modal
                    handleCancelDeletion();
                    setLoadingModal("");

                    // Reload the values
                    setDrivers(drivers.filter(driver => (driver._id !== driverId)));
                    setFilteredDrivers(drivers.filter(driver => (driver._id !== driverId)));
                }
            })
            .catch(err => {
                console.log('Error deleting the driver:', err);
                setError('Error deleting the driver');
                setLoading(false);
                toastifyMessage("error", 'Error deleting the driver');
            });
    };

    // For Deactivate/Reactivate confirmation modal
    const [showDeactivateMessage, setShowDeactivateMessage] = useState(false);
    const [deactivateMessage, setDeactivateMessage] = useState("Are you sure you want to change the driver status?");
    const [driverStatus, setDriverStatus] = useState(false);
    const [deactivateDriverId, setDeactivateDriverId] = useState("");
    const handleCancelDeactivation = () => setShowDeactivateMessage(false)
    const handleShowDeactivateMessage = () => setShowDeactivateMessage(true)

    const deactivateThisDriver = (driverId) => {
        setLoadingModal(true);
        deactivateDriver(driverId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setError(data.error);
                    toastifyMessage("error", 'Error changing the driver status');
                } else {
                    toastifyMessage("success", 'Driver status changed successfully');
      
                    // To close the modal
                    handleCancelDeactivation();
                    setLoadingModal("");

                    // Update the status of the driver to deactivated/reactivated
                    setDrivers(prevDrivers => 
                        prevDrivers.map(driver => 
                            driver._id === driverId ? { ...driver, isDeactivated: !driver.isDeactivated } : driver
                        )
                    );
                    setFilteredDrivers(prevDrivers => 
                        prevDrivers.map(driver => 
                            driver._id === driverId ? { ...driver, isDeactivated: !driver.isDeactivated } : driver
                        )
                    );
                }
            })
            .catch(err => {
                console.log('Error changing the driver status:', err);
                setError('Error changing the driver status');
                setLoading(false);
                toastifyMessage("error", 'Error changing the driver status');
            });
    };

    const handleDriverSearch = (event) => {
        setDriverSearch(event.target.value);
        const searchTerm = (event.target.value).toLowerCase();
        if(searchTerm==="" || !searchTerm){
            setFilteredDrivers(drivers);
            return;
        }
        const filtered = drivers.filter(driver =>
            (driver.firstName + " " + driver.lastName).toLowerCase().includes(searchTerm)
        );
        setFilteredDrivers(filtered);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Base title="Driver Dashboard" description="View/Manage/Deboard the driver">
            <Link className="btn btn-info rounded mb-3" to="/admin/dashboard">
                Admin Home
            </Link>
            <div className="container">
                <h2 className="text-center text-success my-3" style={{ fontFamily: 'Englebert' }}>
                    Drivers
                </h2>
                {loading ? (
                    <LoadingScreen show={loading} />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for a driver"
                                value={driverSearch}
                                onChange={handleDriverSearch}
                            />
                        </div>
                        <div>
                            {filteredDrivers.map(driver => (
                                <div key={driver._id} className="card text-center mb-3">
                                    <div className="card-body">
                                        <h3 className="card-title" style={{ fontFamily: 'Englebert', color: 'black', fontWeight: 'bold', fontSize: '1.2rem' }}>
                                            <span style={{ display: 'block' }}>{driver.firstName} {driver.lastName}</span>
                                            <span style={{ display: 'block', fontSize: '0.8rem', fontWeight: 'normal' }}>Driver ID - {driver.driverNo}</span>
                                            <span style={{ display: 'block', fontSize: '0.8rem', fontWeight: 'normal' }}>Card - {driver.fuelCard && driver.fuelCard.cardNo ? driver.fuelCard.cardNo : ""}</span>
                                        </h3>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-md-2">
                                                <Link className="btn btn-success rounded mb-2" to={`/admin/update/driver/${driver._id}`}>
                                                    Update
                                                </Link>
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeactivateDriverId(driver._id)
                                                            setDriverStatus(driver.isDeactivated)
                                                            handleShowDeactivateMessage()
                                                        }    
                                                    }
                                                    className={`btn rounded mb-2 ${driver.isDeactivated ? 'btn-success' : 'btn-danger'}`}
                                                >
                                                    {driver.isDeactivated ? "Reactivate" : "Deactivate"}
                                                </button>
                                            </div>
                                            
                                            
                                            <div className="col-sm-12 col-md-2">
                                                <button
                                                    onClick={() => 
                                                        {
                                                            setDeleteDriverId(driver._id)
                                                            handleShowDeleteMessage()
                                                        }    
                                                    }
                                                    className="btn btn-danger rounded mb-2"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link className="btn btn-info rounded mb-2" to={`/admin/driver/${driver._id}/view/fuelLogSheets`}>
                                                    Fuel Sheet
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link className="btn btn-info rounded mb-2" to={`/admin/driver/${driver._id}/view/logSheets`}>
                                                    Time Card
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <Link className="btn btn-info rounded mb-2" to={`/admin/driver/${driver._id}/view/tripSheets`}>
                                                    Trip Sheet
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <ToastifyMessage/> 
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisDriver} id={deleteDriverId} message={deleteMessage} loadingModal={loadingModal}  />
            <DeleteConfirmation showModal={showDeactivateMessage} hideModal={handleCancelDeactivation} confirmDeleteModal={deactivateThisDriver} id={deactivateDriverId} message={deactivateMessage} loadingModal={loadingModal} title={driverStatus?"Reactivation confirmation":"Deactivation confirmation"} action={driverStatus?"Reactivate":"Deactivate"}  />
        </Base>
    );
}
