import React, {useState, useEffect} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link,useParams} from "react-router-dom";
import {getadmin, updateadmin} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function UpdateAdmin() {

    const {adminId} = useParams()
    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        adminName: "",
        username: "",
        password: "",
        error: false,
        updatedAdmin: "",
        loading: false,
        isLoaded: "",
        didRedirect: false
    });
    
    const { adminName, username, password , error, updatedAdmin, loading, isLoaded, didRedirect } = values

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getadmin(adminId, admin._id, token).then(data => {
          if(data.error){
            setValues({ ...values, loading: "", isLoaded: "", error: data.error })
          } else{
            setValues({
                ...values,
                ...data,
                error: "",
                loading: "",
                isLoaded: true
            });
            }
        })
        .catch(err => {
            console.log('Error loading the admin:', err);
            setValues({ ...values, error: "Error loading the admin", loading: "" });
            toastifyMessage("error", 'Error loading the admin');
        });  
      }
  
    useEffect(() => {
        preload()
      },[])


    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, updatedAdmin: "", loading: true});

        // Field validations
        const requiredFields = [adminName, username];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedAdmin: ""});
            return;
        }

        updateadmin(adminId, token, admin._id, {adminName, username, password})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", updatedAdmin: ""})
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        ...data,                       
                        loading: "",
                        error: "",
                        updatedAdmin: "Admin updated succesfully"
                    });
                    toastifyMessage("success", 'Admin updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the admin:', err);
                setValues({ ...values, error: "Error updating the admin", loading: "" });
                toastifyMessage("error", 'Error updating the admin');
            });  

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedAdmin ? "" : "none" }}
        >
            <h4>{updatedAdmin}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const updateAdminForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-dark"> Name <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("adminName")}
                    type="text"
                    value={adminName}
                    placeholder={adminName}
                    autoFocus
                    required
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Username <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("username")}
                    type="text"
                    value={username}
                    name="username"
                    placeholder={username}
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Password </label>
                <input
                    className="form-control"
                    onChange={handleChange("password")}
                    type="password"
                    value={password}
                    name="password"
                    placeholder="Password"
                />
            </div>
            <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                Update Admin
            </button>
        </form>
    )

    
    return (
        <Base
        title="Admin Dashboard"
        description="Update the Admin"
        className="container bg-info p-4"
        >
            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                {isLoaded && updateAdminForm()}
                {successMessage()}
                {warningMessage()}
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage/>
        </Base>
    )
}
