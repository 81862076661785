import React, {useState} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link} from "react-router-dom";
import {createtrailer} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import {PROVINCES} from '../../core/Constants';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import { toast } from 'react-toastify';

export default function AddTrailer() {

    const {admin, token} = isAuthenticated()
    const [values, setValues] = useState({
        trailerNumber: "",
        vehicleIdentificationNumber: "",
        plateNumber: "",
        province: "",
        error: false,
        createdTrailer: "",
        loading: false,
        didRedirect: false
    });
    
    const { trailerNumber, vehicleIdentificationNumber, plateNumber, province, error, createdTrailer, loading, didRedirect } = values
  
    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, createdTrailer: "", loading: true})

         // Field validations
         const requiredFields = [trailerNumber, vehicleIdentificationNumber, plateNumber, province];
         if (requiredFields.some(field => !field)) {
             setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", createdTrailer: ""});
             toast.warning('All the fields marked with * are mandatory', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
             return;
         }
 
        createtrailer(admin._id, token, {trailerNumber, vehicleIdentificationNumber, plateNumber, province})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", createdTrailer: "" })
                    toast.warning(data.error, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
                else{
                    setValues({
                        ...values,
                        trailerNumber: "",
                        vehicleIdentificationNumber: "",
                        plateNumber: "",
                        province: "",                        
                        loading: "",
                        error: "",
                        createdTrailer: "Trailer onboarded succesfully"
                    });
                    toast.success('Trailer onboarded successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
            })
            .catch(err => {
                console.log('Error onboarding the trailer:', err);
                setValues({ ...values, error: "Error onboarding the trailer", loading: "" });
                toastifyMessage("error", 'Error onboarding the trailer');
              });

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: createdTrailer ? "" : "none" }}
        >
            <h4>{createdTrailer}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const createTrailerForm = () => (
        <>
            <form className="mt-4">
                <div className="form-group">
                    <label className="text-light"> Trailer Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("trailerNumber")}
                        type="text"
                        value={trailerNumber}
                        name="trailerNumber"
                        placeholder="201"
                        autoFocus
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Vehicle Identification Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("vehicleIdentificationNumber")}
                        type="text"
                        value={vehicleIdentificationNumber}
                        name="vehicleIdentificationNumber"
                        placeholder="YV1MS672892447094"
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Plate Number <span className="text-warning">*</span></label>
                    <input
                        className="form-control"
                        onChange={handleChange("plateNumber")}
                        type="text"
                        value={plateNumber}
                        name="plateNumber"
                        placeholder="ON1MS678"
                    />
                </div>
                <div className="form-group">
                    <label className="text-light"> Province <span className="text-warning">*</span></label>
                    <select
                        onChange={handleChange('province')}
                        className="form-control"
                        placeholder="province"
                    >
                        <option value="" selected={province===""}>Select the Province</option>
                        {PROVINCES &&
                            PROVINCES.map((province, index) => (
                                <option key={index} value={province}>
                                    {province}
                                </option>
                            ))}
                    </select>
                </div>
                <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                    Add Trailer 
                </button>
            </form>
            {LoadingScreen(loading)}
        </>
    )


    return (
        <Base
            title="Trailer Dashboard"
            description="Add a new Trailer"
            className="container bg-info p-4"
        >
            {returnToAdminDashboard()}

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {createTrailerForm()}
                    {successMessage()}
                    {warningMessage()}
                </div>
            </div>
            <ToastifyMessage/> 
        </Base>
    )
}
