import React, {useState} from 'react'
import { Link } from "react-router-dom"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { isAuthenticated } from '../../../auth/helper/adminIndex';
import "../TableView.css";

export default function AuditLogTableView({auditLogs, setDeleteId, handleShowDeleteMessage}) {

    // State variables
    const {admin, token} = isAuthenticated();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      // Retrieve Audit Action
      const getAuditAction = (audit) => {
        let action = "";
        if (audit && audit.action){
            if(audit.action==='D'){
                action = 'Delete';
            }
            else if(audit.action==='DP'){
                action = 'Delete Photo';
            }
        }
        return action;
      }

      // Retrieve Created Date
      const getCreatedDate = (audit) => {
        let localDate = "";
        if (audit && audit.createdAt){
            const utcDate = moment.utc(audit.createdAt);
            localDate = utcDate.local().format('YYYY-MM-DD HH:mm');
        }
        return localDate;
      }

      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    // For View Audit Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [auditData, setAuditData] = useState(null);

    const viewAuditModal = () => (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Audit Log</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                {auditData && auditData.log && Object.entries(JSON.parse(auditData.log)).map(([key, value]) => (
                    <Row key={key}>
                        <Col xs={12} md={8}>
                            <b>{key}:</b> {JSON.stringify(value)}
                        </Col>
                    </Row>
                  ))}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );


    return (
      <div className="table-container">
          <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 200px)' }}>
              <Table stickyHeader className="mui-table" size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="sticky-header-cell">Sr. No.</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Date</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Entity</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Action</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Audit Log</StyledTableCell>
                    <StyledTableCell className="sticky-header-cell" align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditLogs && auditLogs.map((audit, index) => (
                    <StyledTableRow key={index}>         
                        <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                        <StyledTableCell align="center">{getCreatedDate(audit)}</StyledTableCell>
                        <StyledTableCell align="center">{audit.entityType ? audit.entityType : ""}</StyledTableCell>
                        <StyledTableCell align="center">{getAuditAction(audit)}</StyledTableCell>
                        <StyledTableCell align="center">
                                <Stack direction="row" alignItems="center" spacing={1}>
                                      <IconButton size="medium" color="success" onMouseDown={handleMouseDownPassword}
                                      onClick={() => {}}>
                                          <span className="badge bg-success" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}
                                              onClick={() => 
                                                {
                                                  setAuditData(audit);
                                                  handleShow();
                                                } 
                                              }> 
                                            View 
                                          </span>     
                                      </IconButton>
                                </Stack>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  {
                                    (audit.action==="DP" || audit.entityType==="Admin" || !audit.action) &&
                                    <span className="badge bg-info" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}>
                                        Rollback Restricted
                                    </span>  
                                  }

                                  {
                                    audit.action==="D" && audit.status===103 && audit.entityType!=="Admin" &&
                                    <span className="badge bg-success" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}>
                                        Rollback Completed
                                    </span>  
                                  }
                                  
                                  {
                                    audit.action==="D" && audit.status!==103 && audit.entityType!=="Admin" &&
                                    <IconButton size="medium" color="success" onMouseDown={handleMouseDownPassword}
                                          onClick={() => {}}>
                                          <span className="badge bg-danger" style={{ color: 'white', fontSize: '15px', fontFamily: 'helvetica' }}
                                                          onClick={() => 
                                                            {
                                                              setDeleteId(audit);
                                                              handleShowDeleteMessage();
                                                            } 
                                                          }> 
                                                          Rollback 
                                          </span>  
                                      </IconButton>
                                  }
                                </Stack>
                        </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {viewAuditModal()}
        </div>
    )
}