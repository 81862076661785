import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { disAuthenticated } from '../../auth/helper/driverIndex';
import { getincompletetripsheet } from '../helper/driverapicall';
import Base from '../../core/Base';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toastifyMessage } from '../../core/helper/Toast';

const MainTrip = () => {
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tripsheetId, setTripsheetId] = useState('');
    const { driver, token } = disAuthenticated();

    const preload = () => {
        getincompletetripsheet(driver?driver._id:"", token)
            .then(data => {
                const incompleteTripSheet = data.incompleteTripSheet;
                if (incompleteTripSheet && incompleteTripSheet.isIncompleteFlag !== undefined) {
                    if (incompleteTripSheet.isIncompleteFlag) {
                        setFlag(true);
                        setTripsheetId(incompleteTripSheet._id);
                    } else {
                        setFlag(false);
                    }
                } else {
                    console.log('Incomplete trip sheet data is missing or incomplete.');
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching incomplete trip sheet:', error);
                setLoading(false);
                toastifyMessage("error", 'Error fetching incomplete trip sheet');
            });
    }

    useEffect(() => {
        preload();
    }, []);

    return (
            <Base 
                title="Trip Sheet Routing" 
                description="Select the “pick up” button while picking up the load and “drop off” button while dropping the load off" 
                className="container-fluid p-4" 
                style={{ backgroundColor: '#5cb85c' }}>
                <div style={{ minHeight: '100vh', color: 'white' }}>
                    <div className="container mt-5">
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 col-lg-8 d-flex flex-wrap gap-3 justify-content-center">
                                    <div className="col-12 col-md-6 col-lg-3 mb-2">
                                        <Link
                                            to={`/driver/create/tripsheet/pickup`}
                                            className={`btn btn-primary btn-lg w-100 rounded ${flag ? 'btn-secondary disabled' : ''}`}
                                            aria-disabled={flag}
                                        >
                                            Pick Up
                                        </Link>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mb-2">
                                        <Link
                                            to={`/driver/create/tripsheet/dropoff/${tripsheetId}`}
                                            className={`btn btn-primary btn-lg w-100 rounded ${!flag ? 'btn-secondary disabled' : ''}`}
                                            aria-disabled={!flag}
                                        >
                                            Drop Off
                                        </Link>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mb-2">
                                        <Link
                                            to={`/driver/create/tripsheet/noload`}
                                            className="btn btn-primary btn-lg w-100 rounded"
                                        >
                                            No Load
                                        </Link>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 mb-2">
                                        <Link
                                            to={`/driver/create/tripsheet/yardwork`}
                                            className="btn btn-primary btn-lg w-100 rounded"
                                        >
                                            Yard Work
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <ToastifyMessage />
            </Base>
    );
};

export default MainTrip;
