import React, {useState, useEffect} from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from "../../auth/helper/adminIndex";
import {Link,useParams} from "react-router-dom";
import {gettrailer, updatetrailer} from "../helper/adminapicall";
import LoadingScreen from '../../core/helper/LoadingScreen';
import {PROVINCES} from '../../core/Constants';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

export default function UpdateTrailer() {

    const {trailerId} = useParams()
    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        trailerNumber: "",
        vehicleIdentificationNumber: "",
        plateNumber: "",
        province: "",
        error: false,
        updatedTrailer: "",
        loading: false,
        isLoaded: "",
        didRedirect: false
    });
    
    const { trailerNumber, vehicleIdentificationNumber, plateNumber, province, error, updatedTrailer, loading, isLoaded, didRedirect } = values


    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        gettrailer(trailerId, admin._id, token).then(data => {
          if(data.error){
            setValues({ ...values, loading: "", isLoaded: "", error: data.error })
          } else{
            setValues({
                ...values,
                ...data,
                error: "",
                loading: "",
                isLoaded: true
            });
            }
        })
        .catch(err => {
            console.log('Error loading the trailer:', err);
            setValues({ ...values, error: "Error loading the trailer", loading: "" });
            toastifyMessage("error", 'Error loading the trailer');
        });    
      }
  
      useEffect(() => {
        preload()
      },[])


    const returnToAdminDashboard = () => (
        <div className="mt-5">
            <Link 
            className="btn btn-sm btn-dark mb-3 ml-3"
            to = "/admin/dashboard"
            >
                Admin Dashboard
            </Link>
        </div>
    )

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }
    
    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: false, updatedTrailer: "", loading: true})

        // Field validations
        const requiredFields = [trailerNumber, vehicleIdentificationNumber, plateNumber, province];
        if (requiredFields.some(field => !field)) {
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", updatedTrailer: ""});
            return;
        }

        updatetrailer(trailerId, token, admin._id, {trailerNumber, vehicleIdentificationNumber, plateNumber, province})
            .then(data => {
                if(data.error){
                    setValues({ ...values, error: data.error, loading: "", updatedTrailer: "" })
                    toastifyMessage("error", data.error);
                }
                else{
                    setValues({
                        ...values,
                        ...data,                       
                        loading: "",
                        error: "",
                        updatedTrailer: "Trailer updated succesfully"
                    });
                    toastifyMessage("success", 'Trailer updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the trailer:', err);
                setValues({ ...values, error: "Error updating the trailer", loading: "" });
                toastifyMessage("error", 'Error updating the trailer');
            });  

    };

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: updatedTrailer ? "" : "none" }}
        >
            <h4>{updatedTrailer}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );


    const updateTrailerForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-dark"> Trailer Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("trailerNumber")}
                    type="text"
                    value={trailerNumber}
                    placeholder={trailerNumber}
                    autoFocus
                    required
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Vehicle Identification Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("vehicleIdentificationNumber")}
                    type="text"
                    value={vehicleIdentificationNumber}
                    name="vehicleIdentificationNumber"
                    placeholder={vehicleIdentificationNumber}
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Plate Number <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleChange("plateNumber")}
                    type="text"
                    value={plateNumber}
                    name="plateNumber"
                    placeholder={plateNumber}
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Province <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('province')}
                    className="form-control"
                    placeholder="province"
                >
                    {PROVINCES &&
                        PROVINCES.map((p, index) => (
                            <option key={index} value={p} selected={p===province}>
                                {p}
                            </option>
                    ))}
                </select>
            </div>

            <button type="submit" onClick ={onSubmit} className="btn btn-outline-info"> 
                Update Trailer 
            </button>
        </form>
    )

    
    return (
        <Base
        title="Trailer Dashboard"
        description="Update the Trailer Number"
        className="container bg-info p-4"
        >

            {returnToAdminDashboard()}

            <div className="row bg-white text-dark rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1">
                {isLoaded && updateTrailerForm()}
                {successMessage()}
                {warningMessage()}
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage/>
        </Base>
    )
}
