import React, { useState, useEffect } from 'react'
import Base from "../../core/Base"
import { Link, useParams } from "react-router-dom"
import { updatedriver, getdriver, getcards, dropdriverphoto, resetRatePerHour } from "../helper/adminapicall"
import { isAuthenticated } from '../../auth/helper/adminIndex';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import LightLoaderScreen from '../../core/helper/LightLoaderScreen';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import { Modal, Button} from 'react-bootstrap';
import moment from 'moment';

export default function UpdateDriver() {

    const { driverId } = useParams()
    const { admin, token } = isAuthenticated()

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        driverNo: "",
        licenceNo: "",
        city: "",
        state: "",
        country: "",
        streetAddress: "",
        pincode: "",
        contact: "",
        fuelCard: "",
        businessName: "",
        businessAddress: "",
        businessTaxNumber: "",
        ratePerHour: "",
        fuelCards: "",
        driverPhoto: "",
        driverPhotoName: "",
        licencePhoto: "",
        licencePhotoName: "",
        email: "",
        username: "",
        password: "",
        effectiveDate: moment().format('YYYY-MM-DD'),
        newRatePerHour: "",
        loading: false,
        loadingModal: false,
        error: "",
        UpdatedDriver: "",
        UpdatedRatePerHour: "",
        isLoaded: "",
        formData: ""
    });

    const ref1 = React.useRef();
    const ref2 = React.useRef();

    const { firstName, lastName, city, state, country, streetAddress, pincode, contact,
        driverNo, licenceNo, driverPhoto, driverPhotoName, licencePhoto, licencePhotoName, fuelCard, businessName, businessAddress, businessTaxNumber, ratePerHour,
        fuelCards, email, username, password, effectiveDate, newRatePerHour, loading, loadingModal, error, UpdatedDriver, UpdatedRatePerHour, formData, isLoaded } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getdriver(driverId, admin._id, token).then(data1 => {
            if (data1.error) {
                setValues({ ...values, loading: "", isLoaded: "", error: data1.error })
            } else {
                getcards(admin._id, token).then(data2 => {
                    if(data2.error){
                        setValues({ ...values, loading: "", isLoaded: "", error: data2.error })
                    }
                    else{
                        setValues({ ...values, ...data1, fuelCards:data2?data2:[], newRatePerHour:data1&&data1.ratePerHour?data1.ratePerHour:"", loading: "", isLoaded: true, formData: new FormData() })
                    }
                    })
                    .catch(err => {
                        console.log('Error preloading the driver details:', err);
                        setValues({ ...values, error: "Error preloading the driver deatils", loading: "" });
                        toastifyMessage("error", 'Error preloading the driver details');
                    });  
                }
            })
            .catch(err => {
                console.log('Error preloading the driver details:', err);
                setValues({ ...values, error: "Error preloading the driver deatils", loading: "" });
                toastifyMessage("error", 'Error preloading the driver details');
            });  
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        preload();
    }, [])

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: UpdatedDriver ? "" : "none" }}
        >
            <h4>{UpdatedDriver} updated successfully </h4>
        </div>
    )

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error} </h4>
        </div>
    )

    const onDriverSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true, UpdatedDriver: "" })

        updatedriver(driverId, formData, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", UpdatedDriver: "" })
                    toastifyMessage("error", data.error);
                } else {
                    setValues({
                        ...values,
                        ...data,
                        password: "",
                        newRatePerHour: data.ratePerHour?data.ratePerHour:"",
                        licencePhoto: (data && data.licencePhoto && data.licencePhoto.data) ? data.licencePhoto : "",
                        driverPhoto: (data && data.driverPhoto && data.driverPhoto.data) ? data.driverPhoto : "",
                        licencePhotoName: (data && data.licencePhotoName) ? data.licencePhotoName : "",
                        driverPhotoName: (data && data.driverPhotoName) ? data.driverPhotoName : "",
                        formData: new FormData(),
                        loading: "",
                        error: "",
                        UpdatedDriver: data.firstName + " " + data.lastName
                    })
                    ref1.current.value = "";
                    ref2.current.value = "";
                    toastifyMessage("success", "Driver updated successfully");
                }
            })
            .catch(err => {
                console.log('Error updating the client details:', err);
                setValues({ ...values, error: "Error updating the client details", loading: "" });
                toastifyMessage("error", 'Error updating the client details');
            });  

    }

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the photo?");
    const [deletePhotoType, setDeletePhotoType] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    const dropPhoto = (type) => {
        setValues({...values, error: "", loading: true, loadingModal: true, UpdatedDriver: ""})
    
        dropdriverphoto(driverId, {type}, admin._id, token)
        .then(data => {
          if(data.error){
                setValues({...values, error:data.error, loading: "", loadingModal: false, UpdatedDriver: ""})
                toastifyMessage("error", "Error deleting the photo");
          }else{
              setValues({
                  ...values,
                  ...data,
                  loading: false,
                  loadingModal: false,
                  error: "",
                  licencePhotoName: (data && data.licencePhotoName) ? data.licencePhotoName : "",
                  driverPhotoName: (data && data.driverPhotoName) ? data.driverPhotoName : "",
                  UpdatedDriver: data.firstName + " " + data.lastName
              })
              if(type==='driverPhoto'){
                    ref1.current.value = "";
              }
              if(type==='licencePhoto'){
                    ref2.current.value = "";
              }
              toastifyMessage("success", "Photo deleted successfully");
              handleCancelDeletion();         
          }
      })
      .catch(err => {
        console.log('Error deleting the photo:', err);
        setValues({ ...values, error: "Error deleting the photo", loadingModal: "" });
        toastifyMessage("error", 'Error deleting the photo');
        });  
    }

    // Set Rateperhour for the driver and the respective trip cards off the driver using date in effect concept
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleRatePerHourChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const onRatePerHourSubmit = (event) => {
      event.preventDefault();
      setValues({ ...values, error: "", loadingModal: true, UpdatedRatePerHour: "" });
      resetRatePerHour(driverId, {newRatePerHour, effectiveDate}, admin._id, token)
        .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loadingModal: ""});
            } else {
                setValues({
                    ...values,
                    ...data,
                    ratePerHour: newRatePerHour,
                    loadingModal: "",
                    error: "",
                    UpdatedRatePerHour: "Rate per hour updated successfully"
                });
                handleClose();
                toastifyMessage("success", 'Rate per hour updated successfully');
            }
        })
        .catch(err => {
          console.log('Error updating the rate per hour:', err);
          setValues({ ...values, error: "Error updating the rate per hour", loadingModal: "" });
          toastifyMessage("error", 'Error updating the rate per hour');
      });
    };

    const updateRatePerHourtForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-dark"> Effective Date <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleRatePerHourChange("effectiveDate")}
                    type="date"
                    value={effectiveDate}
                    name="effectiveDate"
                />
            </div>
            <div className="form-group">
                <label className="text-dark"> Rate Per Hour <span className="text-warning">*</span></label>
                <input
                    className="form-control"
                    onChange={handleRatePerHourChange("newRatePerHour")}
                    type="number"
                    value={newRatePerHour}
                    name="newRatePerHour"
                    placeholder={newRatePerHour}
                />
            </div>
            <button type="submit" onClick ={onRatePerHourSubmit} className="btn btn-outline-info"> 
                Update Rate/hour 
            </button>
        </form>
    )

    const updateRatePerHourModal = () => (
        <Modal show={show} onHide={handleClose}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Rate per Hour
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    {updateRatePerHourtForm()}
                </Modal.Body>
      
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="btn btn-dark rounded">
                        Close
                    </Button>
                </Modal.Footer>
                
                {LightLoaderScreen(loadingModal)}
            </Modal>
      )

    const handleChange = name => event => {
        let value = ((name === "driverPhoto") || (name === "licencePhoto")) ? event.target.files[0] : event.target.value;
        
        // Validate input for fields that should not contain digits
        if (name === 'firstName' || name === 'lastName' || name === 'city' || name === 'state' || name === 'country') {
            if (!/^[a-zA-Z]*$/.test(value)) {
                return;
            }
        }
        formData.set(name, value)
        setValues({ ...values, [name]: value })

        if(value==="" && formData.get(name)==="" && ((name !== "driverPhoto") && (name !== "licencePhoto"))){
            formData.delete(name);
        }
    };

    const updateDriverForm = () => (
        <form className="mt-4">
            <div className="form-group">
                <label className="text-light"> First Name <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("firstName")}
                    value={firstName}
                    name={firstName}
                    placeholder={firstName}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Last Name <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("lastName")}
                    value={lastName}
                    name={lastName}
                    placeholder={lastName}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Licence No <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("licenceNo")}
                    name={licenceNo}
                    placeholder={licenceNo}
                    value={licenceNo}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Street Address <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("streetAddress")}
                    value={streetAddress}
                    name={streetAddress}
                    placeholder={streetAddress}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> City <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("city")}
                    value={city}
                    name={city}
                    placeholder={city}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> State <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("state")}
                    value={state}
                    name={state}
                    placeholder={state}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Country <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("country")}
                    value={country}
                    name={country}
                    placeholder={country}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Postal Code <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("pincode")}
                    value={pincode}
                    name={pincode}
                    placeholder={pincode}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Contact <span className="text-warning">*</span></label>
                <input type="number" className="form-control"
                    onChange={handleChange("contact")}
                    value={contact}
                    name={contact}
                    placeholder={contact}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Email <span className="text-warning">*</span></label>
                <input type="email" className="form-control"
                    onChange={handleChange("email")}
                    value={email}
                    name={email}
                    placeholder={email}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Business Name <span className="text-warning">*</span></label>
                <input
                  onChange={handleChange("businessName")}
                  className="form-control"
                  name={businessName}
                  placeholder={businessName}
                  value={businessName}
                  type="text"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Business Address <span className="text-warning">*</span></label>
                <input
                  onChange={handleChange("businessAddress")}
                  className="form-control"
                  name={businessAddress}
                  placeholder={businessAddress}
                  value={businessAddress}
                  type="text"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Business Tax Number <span className="text-warning">*</span></label>
                <input
                  onChange={handleChange("businessTaxNumber")}
                  className="form-control"
                  name={businessTaxNumber}
                  placeholder={businessTaxNumber}
                  value={businessTaxNumber}
                  type="text"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Hourly Rate <span className="text-warning">*</span></label>
                <input
                  onChange={handleChange("ratePerHour")}
                  className="form-control"
                  name={ratePerHour}
                  placeholder={ratePerHour}
                  value={ratePerHour}
                  type="number"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Fuel Card <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('fuelCard')}
                    className="form-control"
                    placeholder="fuelCard"
                >
                    {!fuelCard && <option value="" selected={!fuelCard}>Select the Fuel Card</option>}
                    {fuelCards &&
                        fuelCards.map((card, index) => (
                            <option key={index} value={card._id} selected={card.cardNo===((fuelCard && fuelCard.cardNo) ? fuelCard.cardNo : "")}>
                                {card.cardNo}
                            </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light"> Username <span className="text-warning">*</span></label>
                <input type="text" className="form-control"
                    onChange={handleChange("username")}
                    value={username}
                    name={username}
                    placeholder={username}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Password <span className="text-warning">*</span></label>
                <input type="password" className="form-control"
                    onChange={handleChange("password")}
                    value={password}
                    name={password}
                    placeholder="Password"
                />
            </div>

            { driverPhotoName &&
                <div className="form-group">
                    <label className="text-light"> Driver Photo <span className="text-warning"> &nbsp;&nbsp;&nbsp;&nbsp; </span></label>
                    <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={driverId} photoID={driverPhotoName} caption={"Driver Photo"} type={'driverPhoto'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("driverPhoto")}
                                        type="file"
                                        name="driverPhoto"
                                        accept="image"
                                        placeholder="Choose an image for the Driver"
                                        ref={ref1}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoType('driverPhoto');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                    </div>
                </div>
            }

            { driverPhoto && driverPhoto.data &&
                <div className="form-group">
                    <label className="text-light"> Driver Photo <span className="text-warning"> &nbsp;&nbsp;&nbsp;&nbsp; </span></label>
                    {<ImageCard id={driverId} caption={"Driver Photo"} type={'driverPhoto'} />}
                </div>
            }

            { licencePhotoName &&
                <div className="form-group">
                    <label className="text-light"> Licence Photo <span className="text-warning"> &nbsp;&nbsp; </span></label>
                    <div className="d-flex align-items-center mb-2">
                        <ImageCardS3 id={driverId} photoID={licencePhotoName} caption={"Licence Photo"} type={'licencePhoto'} />
                        <div  className="ml-4 mt-2">
                            <label className="btn btn-block btn-outline-success rounded">
                                <input
                                    onChange={handleChange("licencePhoto")}
                                    type="file"
                                    name="licencePhoto"
                                    accept="image"
                                    placeholder="Choose an image for the Driver's Licence"
                                    ref={ref2}
                                    style={{ display: 'none' }}
                                />
                                Update Photo
                            </label>
                        </div>
                        <div  className="ml-4">
                            <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                setDeletePhotoType('licencePhoto');
                                handleShowDeleteMessage();
                            }} >
                                Delete Photo
                            </span>
                        </div>
                    </div>
                </div>
            }

            { licencePhoto && licencePhoto.data &&
                <div className="form-group">
                    <label className="text-light"> Licence Photo <span className="text-warning"> &nbsp;&nbsp; </span></label>
                    {<ImageCard id={driverId} caption={"Licence Photo"} type={'licencePhoto'} />}
                </div>
            }

            {
                !driverPhotoName &&
                <>
                    <span className="text-white">Add Driver Photo &nbsp;&nbsp;&nbsp;</span>
                    <div className="form-group">
                        <label className="btn btn-block btn-success">
                            <input
                                onChange={handleChange("driverPhoto")}
                                type="file"
                                name="driverPhoto"
                                accept="image"
                                placeholder="Choose an image for the Driver"
                                ref={ref1}
                            />
                        </label>
                    </div>
                </>
            }

            {
                !licencePhotoName &&
                <>
                    <span className="text-white">Add Licence Photo &nbsp;&nbsp;&nbsp;</span>
                    <div className="form-group">
                        <label className="btn btn-block btn-success">
                            <input
                                onChange={handleChange("licencePhoto")}
                                type="file"
                                name="licencePhoto"
                                accept="image"
                                placeholder="Choose an image for the Driver's Licence"
                                ref={ref2}
                            />
                        </label>
                    </div>
                </>
            }

            <button type="submit" onClick={onDriverSubmit} className="btn btn-success mb-4">
                Update Driver
            </button>
        </form>
    );

    return (
        <Base
            title="Driver Dashboard"
            description="Edit the driver details"
            className="container bg-success p-4"
        >

            <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3">
                Admin Home
            </Link>

            <span className="btn brn-md btn-dark mx-3 mb-3" onClick={() => {handleShow()} }>
                Update Rate/hour
            </span>

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-20 ">
                    {isLoaded && updateDriverForm()}
                    {successMessage()}
                    {warningMessage()}
                </div>
            </div>
            {LoadingScreen(loading)}
            {updateRatePerHourModal()}
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={dropPhoto} id={deletePhotoType} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/>
        </Base>
    )
}