import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams } from 'react-router-dom';
import { getfuellogsheet, getcards, gettrucks, updatefuellogsheet, dropfuelsheetphoto } from '../helper/driverapicall';
import { disAuthenticated } from '../../auth/helper/driverIndex';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';

const UpdateFuelLogSheet = () => {

    const { fuellogsheetId } = useParams();
    const { driver, token } = disAuthenticated();

    const [values, setValues] = useState({
        cardNum: '',
        cardNo: '',
        otherCardNo: "",
        truckNum: '',
        truckNo: '',
        otherTruckNo: "",
        refuellingDate: '',
        odometer: '',
        def: '',
        fuel: '',
        location: '',
        cards: "",
        trucks: "",
        fuelPhoto1: "",
        fuelPhoto2: "",
        fuelPhoto1Name: "",
        fuelPhoto2Name: "",
        loading: false,
        loadingModal: "",
        isLoaded: "",
        error: '',
        UpdatedFuelLogSheet: '',
        formData: '',
    });

    const {
        cardNum,
        cardNo,
        otherCardNo,
        truckNum,
        truckNo,
        otherTruckNo,
        refuellingDate,
        odometer,
        def,
        fuel,
        location,
        cards,
        trucks,
        fuelPhoto1,
        fuelPhoto2,
        fuelPhoto1Name,
        fuelPhoto2Name,
        loading,
        loadingModal,
        isLoaded,
        error,
        UpdatedFuelLogSheet,
        formData,
    } = values;

    const ref1 = React.useRef();
    const ref2 = React.useRef();

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getcards(driver._id, token).then(data1 => {
            if (data1.error) {
                setValues({ ...values, error: data1.error, loading: "" })
            } else {
                gettrucks(driver._id, token).then(data2 => {
                    if (data2.error) {
                        setValues({ ...values, error: data2.error, loading: ""  })
                    } else {
                        getfuellogsheet(fuellogsheetId, driver._id, token).then(data3 => {
                            if (data3.error) {
                                setValues({ ...values, error: data3.error, loading: ""})
                            } else {
                                const { cardNo, otherCardNo, truckNo, otherTruckNo, refuellingDate, odometer, def, fuel, location, fuelPhoto1, fuelPhoto2, fuelPhoto1Name, fuelPhoto2Name } = data3;
                                        setValues({
                                            ...values,
                                            cards: data1?data1:[],
                                            trucks: data2?data2:[],
                                            cardNum: (cardNo ? cardNo.cardNo : ""),
                                            truckNum: (truckNo ? truckNo.truckNumber : ''),
                                            otherTruckNo: (otherTruckNo ? otherTruckNo : ""),
                                            truckNo:(truckNo ? truckNo.truckNumber : (otherTruckNo ? "other" : "")),
                                            otherCardNo: (otherCardNo ? otherCardNo : ""),
                                            cardNo: (cardNo ? cardNo.cardNo : (otherCardNo ? "other" : "")),
                                            refuellingDate: (refuellingDate ? refuellingDate.substring(0, 10) : ""),
                                            odometer,
                                            def,
                                            fuel,
                                            location,
                                            fuelPhoto1,
                                            fuelPhoto2,
                                            fuelPhoto1Name,
                                            fuelPhoto2Name,
                                            loading: "",
                                            isLoaded: true,
                                            formData: new FormData()
                                        });
                            }
                        })
                        .catch(err => {
                            console.log('Error preloading the fuel sheet', err);
                            setValues({ ...values, error: "Error preloading the fuel sheet", loading: "" });
                            toastifyMessage("error", 'Error preloading the fuel sheet');
                          }); 
                    }
                })
                .catch(err => {
                    console.log('Error preloading the fuel sheet', err);
                    setValues({ ...values, error: "Error preloading the fuel sheet", loading: "" });
                    toastifyMessage("error", 'Error preloading the fuel sheet');
                  }); 
            }
        })
        .catch(err => {
            console.log('Error preloading the fuel sheet', err);
            setValues({ ...values, error: "Error preloading the fuel sheet", loading: "" });
            toastifyMessage("error", 'Error preloading the fuel sheet');
          }); 
    }

    useEffect(() => {
        preload();
    }, []);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };    

    const successMessage = () => (
        <div className="alert alert-success mt-3" style={{ display: UpdatedFuelLogSheet ? '' : 'none' }}>
            <h4>{UpdatedFuelLogSheet}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3" style={{ display: error ? '' : 'none' }}>
            <h4>{error}</h4>
        </div>
    );

    const handleChange = name => event => {
        const value = ((name === "fuelPhoto1") || (name === "fuelPhoto2")) ? event.target.files[0] : event.target.value
        
        if(((name === "cardNo") || (name === "truckNo")) && (value !== "other")){
            if(name==="cardNo"){
                setValues({ ...values, [name]: value, otherCardNo: "" })
                formData.set(name, value)
                if(formData.get("otherCardNo") || formData.get("otherCardNo")===""){
                    formData.delete("otherCardNo")
                }
            }

            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }
        }
        else{
            setValues({ ...values, [name]: value })
            formData.set(name, value)
        }
    };


    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: '', loading: true, UpdatedFuelLogSheet: '' });

        if(cardNo === "other" && (otherCardNo === "")){ 
            setValues({ ...values, error: 'Enter valid card number', loading: "", UpdatedFuelLogSheet: '' });
            toastifyMessage("warning", 'Enter valid card number');
            return;
        }

        if(truckNo === "other" && (otherTruckNo === "")){ 
            setValues({ ...values, error: 'Enter valid truck number', loading: "", UpdatedFuelLogSheet: '' });
            toastifyMessage("warning", 'Enter valid truck number');
            return;
        }

        updatefuellogsheet(fuellogsheetId, formData, driver._id, token)
            .then((data) => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", UpdatedFuelLogSheet: '' });
                    toastifyMessage("error", data.error);
                } else {
                    setValues({
                        ...values,
                        ...data,
                        refuellingDate: (refuellingDate ? refuellingDate.substring(0,10) : ""),
                        loading: "",
                        error: "",
                        fuelPhoto1: (data && data.fuelPhoto1 && data.fuelPhoto1.data) ? data.fuelPhoto1 : "",
                        fuelPhoto2: (data && data.fuelPhoto2 && data.fuelPhoto2.data) ? data.fuelPhoto2 : "",
                        fuelPhoto1Name: (data && data.fuelPhoto1Name) ? data.fuelPhoto1Name : "",
                        fuelPhoto2Name: (data && data.fuelPhoto2Name) ? data.fuelPhoto2Name : "",
                        UpdatedFuelLogSheet: 'Fuel log sheet updated successfully',
                        formData: new FormData()
                    });
                    ref1.current.value = "";
                    ref2.current.value = "";
                    toastifyMessage("success", 'Fuel Sheet updated successfully');
                }
            })
            .catch(err => {
                console.log('Error updating the fuel sheet', err);
                setValues({ ...values, error: "Error updating the fuel sheet", loading: "", UpdatedFuelLogSheet: "" });
                toastifyMessage("error", 'Error updating the fuel sheet');
              }); 
    };

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the photo?");
    const [deletePhotoSheetId, setDeletePhotoSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    const dropPhoto = (type) => {
        setValues({...values, error: "", loading: true, loadingModal: true, UpdatedFuelLogSheet: ""})

        dropfuelsheetphoto(fuellogsheetId, {type}, driver._id, token)
        .then(data => {
          if(data.error){
                setValues({...values, error:data.error, loading: "", loadingModal: "", UpdatedFuelLogSheet: ""})
                toastifyMessage("error", "Error deleting the photo");
          }else{
              setValues({
                  ...values,
                  ...data,
                  refuellingDate:refuellingDate.substring(0,10),
                  loading: false,
                  loadingModal: false,
                  error: "",
                  fuelPhoto1Name: (data && data.fuelPhoto1Name) ? data.fuelPhoto1Name : "",
                  fuelPhoto2Name: (data && data.fuelPhoto2Name) ? data.fuelPhoto2Name : "",
                  UpdatedFuelLogSheet: "Photo deleted successfully",
              })
              ref1.current.value = "";
              ref2.current.value = "";
              toastifyMessage("success", "Photo deleted successfully");
              handleCancelDeletion();           
          }
      })
      .catch(err => {
        console.log('Error deleting the photo:', err);
        setValues({ ...values, error: "Error deleting the photo", loadingModal: "" });
        toastifyMessage("error", 'Error deleting the photo');
        });  
    }

    const updateFuelLogSheetForm = () => (
        <form form className="mt-4">
            <div className="form-group">
                <label className="text-light"> Card Number <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("cardNo")}
                    className="form-control"
                >
                    {cards &&
                        cards.map((card, index) => (
                            <option key={index} value={card._id} selected={card.cardNo===cardNum}>
                                {card.cardNo}
                            </option>
                    ))}
                    <option value="other" selected={cardNo==="other" || cardNo===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( cardNo && (cardNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Card <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherCardNo')}
                    className="form-control"
                    placeholder="Card umber"
                    value={otherCardNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange('truckNo')}
                    className="form-control"
                >
                    
                    {trucks &&
                        trucks.map((truck, index) => (
                            <option key={index} value={truck._id} selected={truck.truckNumber===truckNum}>
                                {truck.truckNumber}
                            </option>
                    ))}
                    <option value="other" selected={truckNo==="other" || truckNo===undefined}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light">Refuelling Date <span className="text-warning">*</span></label>
                <input
                    type="date"
                    className="form-control"
                    onChange={handleChange("refuellingDate")}
                    value={((refuellingDate).substring(0,10))}
                    name={refuellingDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Odometer <span className="text-warning">*</span></label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("odometer")}
                    value={odometer}
                    name={odometer}
                    placeholder={odometer}
                />
            </div>

            <div className="form-group">
                <label className="text-light">DEF (in Litres)<span className="text-warning">*</span></label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("def")}
                    value={def}
                    name={def}
                    placeholder={def}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Fuel (in Litres) <span className="text-warning">*</span></label>
                <input
                    type="number"
                    className="form-control"
                    onChange={handleChange("fuel")}
                    value={fuel}
                    name={fuel}
                    placeholder={fuel}
                />
            </div>

            <div className="form-group">
                <label className="text-light">Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange("location")}
                    value={location}
                    name={location}
                    placeholder={location}
                />
            </div>

            { ((fuelPhoto1Name || (fuelPhoto1 && fuelPhoto1.data)) || (fuelPhoto2Name || (fuelPhoto2 && fuelPhoto2.data))) &&
                <div className="form-group">
                    <label className="text-light"> Refuelling Photos &nbsp;&nbsp;&nbsp;</label>
                    
                    { (fuelPhoto1Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={fuellogsheetId} photoID={fuelPhoto1Name} caption={"Fuel Sheet"} type={'fuelPhoto1'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("fuelPhoto1")}
                                        type="file"
                                        name="fuelPhoto1"
                                        accept="image/*"
                                        placeholder="Choose an Image for refuelling"
                                        ref={ref1}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('fuelPhoto1Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                    }

                    { (fuelPhoto1 && fuelPhoto1.data) &&
                        <ImageCard id={fuellogsheetId} caption={"Fuel Sheet"} type={'fuelPhoto1'} />
                    }

                    { (fuelPhoto2Name) &&
                        <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={fuellogsheetId} photoID={fuelPhoto2Name} caption={"Fuel Sheet"} type={'fuelPhoto2'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("fuelPhoto2")}
                                        type="file"
                                        name="fuelPhoto2"
                                        accept="image/*"
                                        placeholder="Choose an Image for refuelling"
                                        ref={ref2}
                                        style={{ display: 'none' }}
                                    />
                                    Update Photo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoSheetId('fuelPhoto2Name');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Photo
                                </span>
                            </div>
                        </div>
                        
                    }      

                    { (fuelPhoto2 && fuelPhoto2.data) &&
                        <ImageCard id={fuellogsheetId} caption={"Fuel Sheet"} type={'fuelPhoto2'} />
                    }        

                </div>
            }

            {
                (!fuelPhoto1Name || !fuelPhoto2Name)      
                && 
                <span className="text-white"> Add Refuelling Photos <span className="text-warning"> &nbsp;&nbsp;&nbsp; </span></span>
            }

            { 
                !fuelPhoto1Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("fuelPhoto1")}
                            type="file"
                            name="fuelPhoto1"
                            accept="image/*"
                            placeholder="Choose an Image for refuelling"
                            ref={ref1}
                        />
                    </label>
                </div>
            }

            {
                !fuelPhoto2Name &&
                <div className="form-group">
                    <label className="btn btn-block btn-success rounded">
                        <input
                            onChange={handleChange("fuelPhoto2")}
                            type="file"
                            name="fuelPhoto2"
                            accept="image/*"
                            placeholder="Choose an Image for refuelling"
                            ref={ref2}
                        />
                    </label>
                </div>
            }
    
            <button type="submit" onClick={onSubmit} className="btn btn-success mb-4">
                Update Fuel Log Sheet
            </button>
        </form >
    );

    return (
        <Base title="Fuel Log Sheet Dashboard" description="Edit the fuel sheet" className="container bg-success p-4">
            <Link to="/driver/dashboard" className="btn brn-md btn-dark mb-3">
                Driver Home
            </Link>

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-2">
                    {isLoaded && updateFuelLogSheetForm()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                </div>
            </div>
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={dropPhoto} id={deletePhotoSheetId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/> 
        </Base>
    );
};

export default UpdateFuelLogSheet;