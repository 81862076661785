import React, {useState, useEffect} from 'react'
import Base from "../../core/Base"
import { Link ,useParams} from "react-router-dom"
import { updatemerchant, getmerchant, droppartnerlogo,
    resetFuelRate, getCustomerFuelRateHistory, deleteFuelRate, 
    resetWaitRate, getCustomerWaitRateHistory, deleteWaitRate
 } from "../helper/adminapicall"
import {isAuthenticated} from "../../auth/helper/adminIndex"
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LoadingScreen from '../../core/helper/LoadingScreen';
import LightLoaderScreen from '../../core/helper/LightLoaderScreen';
import FuelRateHistoryTableView from '../../core/helper/TableViews/FuelRateHistoryTableView';
import WaitRateHistoryTableView from '../../core/helper/TableViews/WaitRateHistoryTableView';
import ImageCard from "../../core/helper/ImageCard";
import ImageCardS3 from "../../core/helper/ImageCardS3";
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import { Dropdown } from 'react-bootstrap';
import { Modal, Button} from 'react-bootstrap';
import moment from 'moment';

export default function UpdatePartner() {

    const {partnerId} = useParams()
    const {admin, token} = isAuthenticated()

    const [values, setValues] = useState({
        partnerName: "",
        city: "",
        state: "",
        country: "",
        streetAddress: "",
        pincode: "",
        contact: "",
        altcontact: "",
        logo: "",
        logoName: "",
        email: "",
        username: "",
        password: "",
        fuelRate: "",
        waitRate: "",
        newFuelRate: "",
        newWaitRate: "",
        effectiveDate: moment().format('YYYY-MM-DD'),
        fuelRateHistory: "",
        waitRateHistory: "",
        loading: false,
        loadingModal: false,
        fuelRateLoadingModal: false,
        waitRateLoadingModal: false,
        error: "",
        UpdatedPartner: "",
        UpdatedFuelRate: "",
        getaRedirect: false,
        isLoaded: "",
        formData: ""
    });

    const ref1 = React.useRef();

    const {  partnerName, city, state, country, streetAddress, pincode, contact, altcontact, logo, logoName,
      email, username, password, fuelRate, newFuelRate, waitRate, newWaitRate, effectiveDate, fuelRateHistory, waitRateHistory, loading, loadingModal, fuelRateLoadingModal, waitRateLoadingModal,
      error, UpdatedFuelRate, UpdatedPartner, getaRedirect, formData, isLoaded } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: ""})
        getmerchant(partnerId, admin._id, token).then(data => {
          if(data.error) {
                setValues({ ...values, loading: "", isLoaded: "", error: data })
          }else{
                setValues({...values, ...data,
                    newFuelRate: data&&(data.fuelRate || data.fuelRate===0)?data.fuelRate:"",
                    newWaitRate: data&&(data.waitRate || data.waitRate===0)?data.waitRate:"",
                    loading: "", isLoaded: true, formData: new FormData()})
          }
      })
      .catch(err => {
        console.log('Error loading the customer details:', err);
        setValues({ ...values, error: "Error loading the customer deatils", loading: "" });
        toastifyMessage("error", 'Error loading the customer details');
        });  

    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        preload();
    }, [] )

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{display: UpdatedPartner ? "" : "none"}}
        >
            <h4>{UpdatedPartner} updated successfully </h4>
        </div>
    )

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{display: error ? "" : "none"}}
        >
            <h4>{error} </h4>
        </div>
    )

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({...values, error: "", loading: true, UpdatedPartner: ""})

        updatemerchant(partnerId,formData,admin._id,token)
        .then(data => {
          if(data.error){
                setValues({...values, error:data.error, loading: "", UpdatedPartner: ""})
                toastifyMessage("error", data.error);
          }else{
              setValues({
                  ...values,
                  ...data,
                  password: "",
                  newFuelRate: (data.fuelRate||data.fuelRate===0)?data.fuelRate:"",
                  newWaitRate: (data.waitRate||data.waitRate===0)?data.waitRate:"",
                  logo: (data && data.logo && data.logo.data) ? data.logo : "",
                  logoName: (data && data.logoName) ? data.logoName : "",
                  formData: new FormData(),
                  loading: false,
                  error: "",
                  UpdatedPartner: data.partnerName
              })
              ref1.current.value = "";
              toastifyMessage("success", "Customer updated successfully");            
          }
      })
      .catch(err => {
        console.log('Error updating the customer details:', err);
        setValues({ ...values, error: "Error updating the customer deatils", loading: "" });
        toastifyMessage("error", 'Error updating the customer details');
        });  
  }

  // For Delete confirmation modal
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the photo?");
  const [deletePhotoType, setDeletePhotoType] = useState("");
  const handleCancelDeletion = () => setShowDeleteMessage(false);
  const handleShowDeleteMessage = () => setShowDeleteMessage(true);

  const dropPhoto = (type) => {
    setValues({...values, error: "", loading: true, loadingModal: true, UpdatedPartner: ""})

    droppartnerlogo(partnerId, {type}, admin._id, token)
    .then(data => {
      if(data.error){
            setValues({...values, error:data.error, loading: "", loadingModal: "", UpdatedPartner: ""})
            toastifyMessage("error", "Error deleting the customer logo");
      }else{
          setValues({
              ...values,
              ...data,
              loading: false,
              loadingModal: false,
              error: "",
              logoName: (data && data.logoName) ? data.logoName : "",
              UpdatedPartner: data.partnerName
          })
          ref1.current.value = "";
          toastifyMessage("success", "Customer Logo deleted successfully");
          handleCancelDeletion();          
      }
  })
  .catch(err => {
    console.log('Error deleting the customer logo:', err);
    setValues({ ...values, error: "Error deleting the customer logo", loadingModal: "" });
    toastifyMessage("error", 'Error deleting the customer logo');
    });  
}

// Set FuelRate for the customer and the respective trip cards of the customer using date in effect concept
const [showUpdateRate, setShowUpdateRate] = useState(false);
const handleUpdateRateClose = () => setShowUpdateRate(false);
const handleShowUpdateRate = () => setShowUpdateRate(true);

const handleFuelRateChange = name => event => {
    setValues({...values, error: false, [name]: event.target.value})
}

const onFuelRateSubmit = (event) => {
  event.preventDefault();
  setValues({ ...values, error: "", fuelRateLoadingModal: true, UpdatedFuelRate: "" });
  resetFuelRate(partnerId, {newFuelRate, effectiveDate}, admin._id, token)
    .then(data => {
        if (data.error) {
            setValues({ ...values, error: data.error, fuelRateLoadingModal: ""});
        } else {
            setValues({
                ...values,
                ...data,
                fuelRate: newFuelRate,
                fuelRateLoadingModal: "",
                error: "",
                UpdatedFuelRate: "Fuel Rate updated successfully",
                effectiveDate: moment().format('YYYY-MM-DD')
            });
            handleUpdateRateClose();
            toastifyMessage("success", 'Fuel rate updated successfully');
        }
    })
    .catch(err => {
      console.log('Error updating the fuel rate:', err);
      setValues({ ...values, error: "Error updating the fuel rate", fuelRateLoadingModal: "" });
      toastifyMessage("error", 'Error updating the fuel rate');
  });
};

const updateFuelRateForm = () => (
    <form className="mt-4">
        <div className="form-group">
            <label className="text-dark"> Effective Date <span className="text-warning">*</span></label>
            <input
                className="form-control"
                onChange={handleFuelRateChange("effectiveDate")}
                type="date"
                value={effectiveDate}
                name="effectiveDate"
            />
        </div>
        <div className="form-group">
            <label className="text-dark"> Fuel S/C Rate (in %) <span className="text-warning">*</span></label>
            <input
                className="form-control"
                onChange={handleFuelRateChange("newFuelRate")}
                type="number"
                value={newFuelRate}
                name="newFuelRate"
                placeholder={newFuelRate}
            />
        </div>
        <button type="submit" onClick ={onFuelRateSubmit} className="btn btn-outline-info"> 
            Update Fuel Rate 
        </button>
    </form>
)

const updateFuelRateModal = () => (
    <Modal show={showUpdateRate} onHide={handleUpdateRateClose}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Fuel Rate
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                {updateFuelRateForm()}
            </Modal.Body>
  
            <Modal.Footer>
                <Button variant="secondary" onClick={handleUpdateRateClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
            
            {LightLoaderScreen(fuelRateLoadingModal)}
        </Modal>
  )

  // Fuel rate History
const [showRateHistory, setShowRateHistory] = useState(false);
const handleRateHistoryClose = () => setShowRateHistory(false);

const handleShowRateHistory = () => {
    setShowRateHistory(true);
    setValues({ ...values, error: "", loadingModal: true });
    getCustomerFuelRateHistory(partnerId, admin._id, token)
        .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loadingModal: ""});
            } else {
                setValues({
                    ...values,
                    fuelRateHistory: data,
                    loadingModal: "",
                    error: ""
                });
            }
    })
    .catch(err => {
      console.log('Error fetching the fuel rate history:', err);
      setValues({ ...values, error: "Error fetching the fuel rate history", loadingModal: "" });
      toastifyMessage("error", 'Error fetching the fuel rate history');
  });
};

const fuelRateHistoryModal = () => (
    <Modal show={showRateHistory} onHide={handleRateHistoryClose}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Fuel Rate History
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <FuelRateHistoryTableView fuelRates={fuelRateHistory} setDeleteFuelRateId={setDeleteFuelRateId} handleShowDeleteMessage={handleFuelRateShowDeleteMessage}/>
            </Modal.Body>
  
            <Modal.Footer>
                <Button variant="secondary" onClick={handleRateHistoryClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>

            {LightLoaderScreen(loadingModal)}
        </Modal>
  )

// Set Wait Rate for the customer and the respective trip cards of the customer using date in effect concept
const [showUpdateWaitRate, setShowUpdateWaitRate] = useState(false);
const handleUpdateWaitRateClose = () => setShowUpdateWaitRate(false);
const handleShowUpdateWaitRate = () => setShowUpdateWaitRate(true);

const handleWaitRateChange = name => event => {
    setValues({...values, error: false, [name]: event.target.value})
}

const onWaitRateSubmit = (event) => {
  event.preventDefault();
  setValues({ ...values, error: "", waitRateLoadingModal: true, UpdatedFuelRate: "" });
  resetWaitRate(partnerId, {newWaitRate, effectiveDate}, admin._id, token)
    .then(data => {
        if (data.error) {
            setValues({ ...values, error: data.error, waitRateLoadingModal: ""});
        } else {
            setValues({
                ...values,
                ...data,
                waitRate: newWaitRate,
                waitRateLoadingModal: "",
                error: "",
                UpdatedFuelRate: "Wait Rate updated successfully",
                effectiveDate: moment().format('YYYY-MM-DD')
            });
            handleUpdateWaitRateClose();
            toastifyMessage("success", 'Wait rate updated successfully');
        }
    })
    .catch(err => {
      console.log('Error updating the wait rate:', err);
      setValues({ ...values, error: "Error updating the wait rate", waitRateLoadingModal: "" });
      toastifyMessage("error", 'Error updating the wait rate');
  });
};

const updateWaitRateForm = () => (
    <form className="mt-4">
        <div className="form-group">
            <label className="text-dark"> Effective Date <span className="text-warning">*</span></label>
            <input
                className="form-control"
                onChange={handleWaitRateChange("effectiveDate")}
                type="date"
                value={effectiveDate}
                name="effectiveDate"
            />
        </div>
        <div className="form-group">
            <label className="text-dark"> Wait Rate <span className="text-warning">*</span></label>
            <input
                className="form-control"
                onChange={handleWaitRateChange("newWaitRate")}
                type="number"
                value={newWaitRate}
                name="newWaitRate"
                placeholder={newWaitRate}
            />
        </div>
        <button type="submit" onClick ={onWaitRateSubmit} className="btn btn-outline-info"> 
            Update Wait Rate 
        </button>
    </form>
)

const updateWaitRateModal = () => (
    <Modal show={showUpdateWaitRate} onHide={handleUpdateWaitRateClose}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Wait Rate
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                {updateWaitRateForm()}
            </Modal.Body>
  
            <Modal.Footer>
                <Button variant="secondary" onClick={handleUpdateWaitRateClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>
            
            {LightLoaderScreen(waitRateLoadingModal)}
        </Modal>
  )

  // Wait rate History
const [showWaitRateHistory, setShowWaitRateHistory] = useState(false);
const handleWaitRateHistoryClose = () => setShowWaitRateHistory(false);

const handleShowWaitRateHistory = () => {
    setShowWaitRateHistory(true);
    setValues({ ...values, error: "", loadingModal: true });
    getCustomerWaitRateHistory(partnerId, admin._id, token)
        .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loadingModal: ""});
            } else {
                setValues({
                    ...values,
                    waitRateHistory: data,
                    loadingModal: "",
                    error: ""
                });
            }
    })
    .catch(err => {
      console.log('Error fetching the wait rate history:', err);
      setValues({ ...values, error: "Error fetching the wait rate history", loadingModal: "" });
      toastifyMessage("error", 'Error fetching the wait rate history');
  });
};

const waitRateHistoryModal = () => (
    <Modal show={showWaitRateHistory} onHide={handleWaitRateHistoryClose}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Wait Rate History
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <WaitRateHistoryTableView waitRates={waitRateHistory} setDeleteWaitRateId={setDeleteWaitRateId} handleShowDeleteMessage={handleWaitRateShowDeleteMessage}/>
            </Modal.Body>
  
            <Modal.Footer>
                <Button variant="secondary" onClick={handleWaitRateHistoryClose} className="btn btn-dark rounded">
                    Close
                </Button>
            </Modal.Footer>

            {LightLoaderScreen(loadingModal)}
        </Modal>
  )

    const handleChange = name => event => {
        const value = name ==="logo" ? event.target.files[0] : event.target.value
        formData.set(name, value)
        setValues({...values, [name]:value})
        if(value==="" && formData.get(name)==="" && name!=="logo"){
            formData.delete(name);
        }
    };
    
    const updatePartnerForm = () => (
        <form className="mt-4">
          <div className="form-group">
                            <label className="text-light"> Customer Name <span className="text-warning">*</span></label>
                            <input type="text"  className="form-control" 
                            onChange={handleChange("partnerName")}
                            value={partnerName}
                            name={partnerName}
                            placeholder={partnerName}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> Street Address <span className="text-warning">*</span></label>
                            <input type="text" className="form-control"
                            onChange={handleChange("streetAddress")}
                            value={streetAddress}
                            name={streetAddress}
                            placeholder={streetAddress}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> City <span className="text-warning">*</span></label>
                            <input type="text"  className="form-control" 
                            onChange={handleChange("city")}
                            value={city}
                            name={city}
                            placeholder={city}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> Province <span className="text-warning">*</span></label>
                            <input type="text" className="form-control"
                            onChange={handleChange("state")}
                            value={state}
                            name={state}
                            placeholder={state}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> Country <span className="text-warning">*</span></label>
                            <input type="text" className="form-control"
                            onChange={handleChange("country")}
                            value={country}
                            name={country}
                            placeholder={country}
                            />
            </div>    
            <div className="form-group">
                            <label className="text-light"> Postal Code <span className="text-warning">*</span></label>
                            <input type="text" className="form-control"
                            onChange={handleChange("pincode")}
                            value={pincode}
                            name={pincode}
                            placeholder={pincode}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> Contact <span className="text-warning">*</span></label>
                            <input type="number" className="form-control"
                            onChange={handleChange("contact")}
                            value={contact}
                            name={contact}
                            placeholder={contact}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light">Alternate Contact </label>
                            <input type="number" className="form-control"
                            onChange={handleChange("altcontact")}
                            value={altcontact}
                            name={altcontact}
                            placeholder={!altcontact ? "Alternate Contact" : altcontact}
                            />
            </div>
            <div className="form-group">
                <label className="text-light"> Fuel S/C Rate (in %) </label>
                <input type="number"
                  onChange={handleChange("fuelRate")}
                  className="form-control"
                  name={fuelRate}
                  placeholder={fuelRate}
                  value={fuelRate}
                />
            </div>
            <div className="form-group">
                <label className="text-light"> Waiting Rate (in $) </label>
                <input type="number"
                  onChange={handleChange("waitRate")}
                  className="form-control"
                  name={waitRate}
                  placeholder={waitRate}
                  value={waitRate}
                />
            </div>         
            <div className="form-group">
                            <label className="text-light"> Username <span className="text-warning">*</span></label>
                            <input type="text"  className="form-control" 
                            onChange={handleChange("username")}
                            value={username}
                            name={username}
                            placeholder={username}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> Email <span className="text-warning">*</span></label>
                            <input type="email" className="form-control"
                            onChange={handleChange("email")}
                            value={email}
                            name={email}
                            placeholder={email}
                            />
            </div>
            <div className="form-group">
                            <label className="text-light"> Password <span className="text-warning">*</span></label>
                            <input type="password" className="form-control"
                            onChange={handleChange("password")}
                            value={password}
                            name={password}
                            placeholder="Password"
                            />
            </div>

            { logoName &&
                <div className="form-group">
                    <label className="text-light"> Customer Logo <span className="text-warning"> &nbsp;&nbsp;&nbsp;&nbsp; </span></label>
                    <div className="d-flex align-items-center mb-2">
                            <ImageCardS3 id={partnerId} photoID={logoName} caption={"Client Logo"} type={'logo'} />
                            <div  className="ml-4 mt-2">
                                <label className="btn btn-block btn-outline-success rounded">
                                    <input
                                        onChange={handleChange("logo")}
                                        type="file"
                                        name="logo"
                                        accept="image"
                                        placeholder="Choose a logo"
                                        ref={ref1}
                                        style={{ display: 'none' }}
                                    />
                                    Update Logo
                                </label>
                            </div>
                            <div  className="ml-4">
                                <span className="btn btn-block btn-outline-danger rounded" onClick={() => {
                                    setDeletePhotoType('logo');
                                    handleShowDeleteMessage();
                                }} >
                                    Delete Logo
                                </span>
                            </div>
                    </div>
                </div>
            }

            { logo && logo.data &&
                <div className="form-group">
                    <label className="text-light"> Customer Logo <span className="text-warning"> &nbsp;&nbsp;&nbsp;&nbsp; </span></label>
                    {<ImageCard id={partnerId} caption={"Client Logo"} type={'logo'} />}
                </div>
            }

            {
                !logoName &&
                <> 
                    <span className="text-white"> Add Customer Logo &nbsp;&nbsp;&nbsp;</span>
                    <div className="form-group">
                        <label className="btn btn-block btn-success">
                            <input
                            onChange={handleChange("logo")}
                            type="file"
                            name="logo"
                            accept="image"
                            placeholder="Choose a logo"
                            ref={ref1}
                            />
                        </label>
                    </div>
                </>
            }        

            <button type="submit" onClick={onSubmit} className="btn btn-success mb-4">
                Update Customer
            </button>
        </form>
      );

    // For Delete Fuel Rate confirmation modal
    const [showDeleteFuelRateMessage, setShowDeleteFuelRateMessage] = useState(false);
    const [deleteFuelRateMessage, setDeleteFuelRateMessage] = useState("Are you sure you want to delete the fuel rate?");
    const [deleteFuelRateId, setDeleteFuelRateId] = useState("");
    const handleFuelRateCancelDeletion = () => setShowDeleteFuelRateMessage(false);
    const handleFuelRateShowDeleteMessage = () => setShowDeleteFuelRateMessage(true);

    // Delete Fuel rate
    const deleteThisFuelRate = (fuelRateId) => {
        setValues({ ...values, error: "", fuelRateLoadingModal: true});
        deleteFuelRate(fuelRateId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, fuelRateLoadingModal: "" });
                    toastifyMessage("error", 'Error deleting the fuel rate');
                } else {
                    toastifyMessage("success", "Fuel rate deleted successfully");
                
                    // To close the modal & reload the values
                    handleFuelRateCancelDeletion();
                    setValues({ ...values, error: "", fuelRateLoadingModal: "", fuelRateHistory: (fuelRateHistory.filter(rate => (rate._id !== fuelRateId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the fuel rate:', err);
                setValues({ ...values, error: "Error deleting the fuel rate", fuelRateLoadingModal: "" });
                toastifyMessage("error", 'Error deleting the fuel rate');
            });
    };

    // For Delete Wait Rate confirmation modal
    const [showDeleteWaitRateMessage, setShowDeleteWaitRateMessage] = useState(false);
    const [deleteWaitRateMessage, setDeleteWaitRateMessage] = useState("Are you sure you want to delete the wait rate?");
    const [deleteWaitRateId, setDeleteWaitRateId] = useState("");
    const handleWaitRateCancelDeletion = () => setShowDeleteWaitRateMessage(false);
    const handleWaitRateShowDeleteMessage = () => setShowDeleteWaitRateMessage(true);

    // Delete Wait rate
    const deleteThisWaitRate = (waitRateId) => {
        setValues({ ...values, error: "", waitRateLoadingModal: true});
        deleteWaitRate(waitRateId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, waitRateLoadingModal: "" });
                    toastifyMessage("error", 'Error deleting the wait rate');
                } else {
                    toastifyMessage("success", "Wait rate deleted successfully");
                
                    // To close the modal & reload the values
                    handleWaitRateCancelDeletion();
                    setValues({ ...values, error: "", waitRateLoadingModal: "", waitRateHistory: (waitRateHistory.filter(rate => (rate._id !== waitRateId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the wait rate:', err);
                setValues({ ...values, error: "Error deleting the wait rate", waitRateLoadingModal: "" });
                toastifyMessage("error", 'Error deleting the wait rate');
            });
    };
    
    return(
        <Base
        title="Customer Dashboard"
        description="Edit the customer details"
        className="container bg-success p-4"
        >
        
            <Link to="/admin/dashboard" className="btn brn-md btn-dark mb-3 rounded">
                Admin Home
            </Link>

            <Dropdown as="li" className="btn brn-md btn-dark ml-3 mb-3 p-0 rounded">
                        <Dropdown.Toggle variant="dark" id="dropdown-partners">
                            Rate
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as="span" onClick={() => {handleShowUpdateRate()}}>Update Fuel Rate</Dropdown.Item>
                            <Dropdown.Item as="span" onClick={() => {handleShowRateHistory()}}>Fuel Rate History</Dropdown.Item>
                            <Dropdown.Item as="span" onClick={() => {handleShowUpdateWaitRate()}}>Update Wait Rate</Dropdown.Item>
                            <Dropdown.Item as="span" onClick={() => {handleShowWaitRateHistory()}}>Wait Rate History</Dropdown.Item>
                        </Dropdown.Menu>
            </Dropdown>

            <div className="row bg-dark test-white rounded center">
                <div className="col-md-8 offset-md-20 ">
                    {isLoaded && updatePartnerForm()}
                    {successMessage()}
                    {warningMessage()}
                </div>
            </div>
            {LoadingScreen(loading)}
            {updateFuelRateModal()}
            {fuelRateHistoryModal()}
            {updateWaitRateModal()}
            {waitRateHistoryModal()}
            <DeleteConfirmation showModal={showDeleteFuelRateMessage} hideModal={handleFuelRateCancelDeletion} confirmDeleteModal={deleteThisFuelRate} id={deleteFuelRateId} message={deleteFuelRateMessage} loadingModal={fuelRateLoadingModal}/>
            <DeleteConfirmation showModal={showDeleteWaitRateMessage} hideModal={handleWaitRateCancelDeletion} confirmDeleteModal={deleteThisWaitRate} id={deleteWaitRateId} message={deleteWaitRateMessage} loadingModal={waitRateLoadingModal}/>
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={dropPhoto} id={deletePhotoType} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage/>
        </Base>
    )
}